<template>
  <div id="dnw-page" style="height: auto !important">
    <div id="dnw-main" style="height: auto !important">
      <center style="height: auto !important">
        <div class="dnw-sheet" style="height: auto !important">
          <router-view />
        </div>
      </center>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "Default App Title",
    titleTemplate: "%s | vue-meta Example App",
    htmlAttrs: {
      reptilian: "gator",
    },
    headAttrs: {
      nest: "eggs",
    },
    meta: [
      { charset: "utf-8" },
      { name: "description", content: "gator" },
      { name: "viewport", content: "width=1024, initial-scale=1" },
    ],
  },
  data() {
    return {
      //
    };
  },
};
</script>
