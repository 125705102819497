<template>
  <div
    class="dnw-content-main"
    id="dnw-content-main"
    style="height: auto !important"
  >
    <div class="dnw-content-main-l" style="height: auto !important">
      <div class="dnw-content-main-r" style="height: auto !important">
        <table
          width="100%"
          border="0"
          cellspacing="0"
          cellpadding="0"
          style="height: auto !important"
        >
          <tbody>
            <tr style="height: auto !important">
              <td class="left" style="height: auto !important">
                <div id="dnw-left">
                  <div>
                    <div>
                      <div class="modulesLR moduleBrown">
                        <div class="title-l">
                          <div class="title-r">
                            <h1>
                              <a href="/xo-so-truc-tiep.html"
                                >Tường Thuật Trực Tiếp Xổ Số</a
                              >
                            </h1>
                          </div>
                        </div>
                        <div class="body-l">
                          <div class="body-r">
                            <ul class="menu menuxosott">
                              <li>
                                <a
                                  href="/xo-so-truc-tiep/mien-nam.html"
                                  title="Trực Tiếp Xổ Số Miền Nam"
                                  ><span
                                    id="mnxstt_mien-nam"
                                    class="icon finnish"
                                    >Trực Tiếp Xổ Số Miền Nam</span
                                  ></a
                                >
                              </li>
                              <li>
                                <a
                                  href="/xo-so-truc-tiep/mien-bac.html"
                                  title="Trực Tiếp Xổ Số Miền Bắc"
                                  ><span id="mnxstt_mien-bac" class="icon wait"
                                    >Trực Tiếp Xổ Số Miền Bắc</span
                                  ></a
                                >
                              </li>
                              <li>
                                <a
                                  href="/xo-so-truc-tiep/mien-trung.html"
                                  title="Trực Tiếp Xổ Số Miền Trung"
                                  ><span
                                    id="mnxstt_mien-trung"
                                    class="icon running"
                                    >Trực Tiếp Xổ Số Miền Trung</span
                                  ></a
                                >
                              </li>
                              <li>
                                <a
                                  href="/ket-qua-xo-so/dien-toan-vietlott.html"
                                  title="Trực Tiếp Xổ Số Vietlott"
                                  ><span
                                    id="mnxstt_dien-toan-vietlott"
                                    class="icon running"
                                    >Trực Tiếp Xổ Số Vietlott</span
                                  ></a
                                >
                              </li>
                            </ul>
                            <ul class="menu">
                              <li>
                                <div>
                                  <img
                                    alt=""
                                    height="10"
                                    src="@/assets/choxoso.gif"
                                    width="30"
                                  />&nbsp;chờ,
                                  <img
                                    alt=""
                                    height="8"
                                    src="@/assets/tructiepxoso.gif"
                                    width="35"
                                  />&nbsp;đang xổ,
                                  <img
                                    alt=""
                                    height="10"
                                    src="@/assets/ok_daxosoxong.png"
                                    width="10"
                                  />
                                  mới
                                </div>

                                <ul>
                                  <li style="text-align: right">
                                    <a
                                      href="/thong-tin/lich-quay-so-mo-thuong.html"
                                      >Lịch Mở Thưởng</a
                                    >
                                  </li>
                                  <li style="text-align: right">
                                    <a href="/tao-ma-nhung/ket-qua-xo-so.html">
                                      <img
                                        alt=""
                                        src="@/assets/tools.png"
                                        border="0"
                                      />
                                      Chèn Kqxs vào Websites
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="clear"></div>
                    </div>
                    <div style="margin-top: 10px">
                      <div>
                        <strong
                          ><a
                            class="dvs_linkdoisotrung"
                            href="/thong-tin/doi-so-trung.html"
                            >ĐỔI SỐ TRÚNG ĐẶC BIỆT</a
                          ></strong
                        >
                      </div>
                      <div class="clear"></div>
                    </div>
                    <div style="margin-top: 10px">
                      <div class="modulesLR moduleBrown">
                        <div style="display: none">Kết quả xổ số Hôm Nay</div>
                        <div id="ketquaxosohomnay">
                          <div class="title-l">
                            <div class="title-r" style="position: relative">
                              <a
                                class="next-day"
                                href="javascript:getlichxosohomnay('2021-02-05');"
                                >&lt;</a
                              >
                              <h1 style="text-align: center">
                                Kết quả xổ số Hôm Nay<small
                                  >Thứ bảy - Ngày 06/02/2021</small
                                >
                              </h1>
                              <a
                                class="previous-day"
                                href="javascript:getlichxosohomnay('2021-02-07');"
                                >&gt;</a
                              >
                            </div>
                          </div>
                          <div class="body-l">
                            <div class="body-r">
                              <ul class="menu menulichxoso">
                                <li>
                                  <a
                                    class="hottoday_mien"
                                    href="/ket-qua-xo-so/mien-nam.html"
                                    title="Kết quả xổ Số Miền Nam"
                                    ><strong>Kết quả xổ Số Miền Nam</strong></a
                                  >
                                  <ul>
                                    <li>
                                      <a
                                        href="/ket-qua-xo-so/mien-nam/binh-phuoc.html"
                                        title="Kết quả xổ Số Bình Phước"
                                        ><span class="icon finnish"
                                          >Kết quả xổ Số Bình Phước</span
                                        ></a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        href="/ket-qua-xo-so/mien-nam/hau-giang.html"
                                        title="Kết quả xổ Số Hậu Giang"
                                        ><span class="icon wait"
                                          >Kết quả xổ Số Hậu Giang</span
                                        ></a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        href="/ket-qua-xo-so/mien-nam/long-an.html"
                                        title="Kết quả xổ Số Long An"
                                        ><span class="icon running"
                                          >Kết quả xổ Số Long An</span
                                        ></a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        href="/ket-qua-xo-so/mien-nam/tp-hcm.html"
                                        title="Kết quả xổ Số TP. HCM"
                                        ><span class="icon running"
                                          >Kết quả xổ Số TP. HCM</span
                                        ></a
                                      >
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    class="hottoday_mien"
                                    href="/ket-qua-xo-so/mien-bac.html"
                                    title="Kết quả xổ Số Miền Bắc"
                                    ><strong>Kết quả xổ Số Miền Bắc</strong></a
                                  >
                                  <ul>
                                    <li>
                                      <a
                                        href="/ket-qua-xo-so/mien-bac/nam-dinh.html"
                                        title="Kết quả xổ Số Nam Định"
                                        ><span class="icon wait"
                                          >Kết quả xổ Số Nam Định</span
                                        ></a
                                      >
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    class="hottoday_mien"
                                    href="/ket-qua-xo-so/mien-trung.html"
                                    title="Kết quả xổ Số Miền Trung"
                                    ><strong
                                      >Kết quả xổ Số Miền Trung</strong
                                    ></a
                                  >
                                  <ul>
                                    <li>
                                      <a
                                        href="/ket-qua-xo-so/mien-trung/da-nang.html"
                                        title="Kết quả xổ Số Đà Nẵng"
                                        ><span class="icon finnish"
                                          >Kết quả xổ Số Đà Nẵng</span
                                        ></a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        href="/ket-qua-xo-so/mien-trung/dak-nong.html"
                                        title="Kết quả xổ Số Đắk Nông"
                                        ><span class="icon wait"
                                          >Kết quả xổ Số Đắk Nông</span
                                        ></a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        href="/ket-qua-xo-so/mien-trung/quang-ngai.html"
                                        title="Kết quả xổ Số Quảng Ngãi"
                                        ><span class="icon running"
                                          >Kết quả xổ Số Quảng Ngãi</span
                                        ></a
                                      >
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  <a
                                    class="hottoday_mien"
                                    href="/xo-so-dien-toan.html"
                                    title="Kết quả xổ Số Điện Toán"
                                    ><strong>Kết quả xổ Số Điện Toán</strong></a
                                  >
                                  <ul>
                                    <li>
                                      <a
                                        href="/xo-so-dien-toan/1*2*3.html"
                                        title="Kết quả xổ Số 1*2*3"
                                        ><span class="icon running"
                                          >Kết quả xổ Số 1*2*3</span
                                        ></a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        href="/xo-so-dien-toan/6x36.html"
                                        title="Kết quả xổ Số 6X36"
                                        ><span class="icon finnish"
                                          >Kết quả xổ Số 6X36</span
                                        ></a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        href="/xo-so-dien-toan/than-tai-4.html"
                                        title="Kết quả xổ Số Thần Tài 4"
                                        ><span class="icon finnish"
                                          >Kết quả xổ Số Thần Tài 4</span
                                        ></a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        href="/ket-qua-xo-so/dien-toan-vietlott/power-6x55.html"
                                        title="Kết quả xổ Số Power 6/55"
                                        ><span class="icon wait"
                                          >Kết quả xổ Số Power 6/55</span
                                        ></a
                                      >
                                    </li>
                                    <li>
                                      <a
                                        href="/ket-qua-xo-so/dien-toan-vietlott/max-4d.html"
                                        title="Kết quả xổ Số Max 4D"
                                        ><span class="icon wait"
                                          >Kết quả xổ Số Max 4D</span
                                        ></a
                                      >
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="body-l">
                          <div class="body-r">
                            <p>
                              <img
                                alt=""
                                height="10"
                                src="@/assets/choxoso.gif"
                                width="30"
                              />&nbsp;chờ, &nbsp;
                              <img
                                alt=""
                                height="8"
                                src="@/assets/tructiepxoso.gif"
                                width="31"
                              />&nbsp;đang xổ, &nbsp;
                              <img
                                alt=""
                                height="10"
                                src="@/assets/ok_daxosoxong.png"
                                width="10"
                              />&nbsp;mới
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="clear"></div>
                    </div>
                  </div>
                </div>
                <div
                  id="parent_left"
                  style="position: relative; overflow: hidden; height: auto"
                >
                  <div
                    id="sidebarleft"
                    style="
                      position: relative;
                      top: 0px;
                      height: auto !important;
                      padding-top: 2px;
                    "
                  >
                    <div style="height: auto !important">
                      <div style="height: auto !important; margin-top: 10px">
                        <table
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          style="width: 200px"
                        >
                          <tbody>
                            <tr>
                              <td>
                                <img
                                  alt=""
                                  src="@/assets/ads1.png"
                                  border="0"
                                  height="395"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="clear"></div>
                      </div>
                      <div>
                        <div class="modulesLR moduleBrown">
                          <div class="body-l">
                            <div class="body-r">
                              <ul class="list_news">
                                <li>
                                  <a
                                    href="/help/41-co-gi-moi-trong-phien-ban-nay.html"
                                    >Có gì mới trong phiên bản này</a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="/help/40-huong-dan-dang-ky-thanh-vien-in-ve-do-va-ban-xo-so-online.html"
                                    >Hướng dẫn đăng ký thành viên, in vé dò và
                                    bán xổ số online</a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="/help/44-huong-dan-in-ve-do-ket-qua-xo-so.html"
                                    >Hướng Dẫn In Vé Dò Kết Quả Xổ Số</a
                                  >
                                </li>
                                <li>
                                  <a href="/help/54-in-ve-do-tren-chrome.html"
                                    >IN VÉ DÒ TRÊN CHROME</a
                                  >
                                </li>
                                <li>
                                  <a href="/help/56-in-ve-do-tren-firefox.html"
                                    >IN VÉ DÒ TRÊN FIREFOX</a
                                  >
                                </li>
                                <li>
                                  <a
                                    href="/help/42-lay-ket-qua-xo-so-ve-websites-cua-ban.html"
                                    >Lấy kết quả xổ số về websites của bạn</a
                                  >
                                </li>
                                <li>
                                  <a href="/help/55-thiet-lap-kho-giay-a4.html"
                                    >THIẾT LẬP KHỔ GIẤY A4</a
                                  >
                                </li>
                                <ul></ul>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="clear"></div>
                      </div>
                      <div style="margin-top: 10px">
                        <ul
                          id="adv23"
                          style="
                            margin: 0px auto;
                            padding: 0px;
                            width: 200px;
                            height: 288px;
                            overflow: hidden;
                          "
                        >
                          <li
                            id="adv23_19419_0"
                            style="opacity: 1; display: block"
                          >
                            <a
                              href="http://sms.xoso.net/8x77_01/"
                              target="_blank"
                            >
                              <img
                                src="@/assets/biquyettrunglon-2.gif"
                                border="0"
                                width="200"
                                height="288"
                              />
                            </a>
                          </li>
                        </ul>
                        <div class="clear"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td style="height: auto !important">
                <div id="right_main" style="height: auto !important">
                  <table
                    width="100%"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                    style="height: auto !important"
                  >
                    <tbody>
                      <tr style="height: auto !important">
                        <td class="main">
                          <div class="modules_top">
                            <div>
                              <div id="hottoday">
                                <a
                                  class="btnprev"
                                  href="javascript:getlichxoso('06-02-2021');"
                                ></a>
                                <a
                                  class="btnnext"
                                  href="javascript:getlichxoso('08-02-2021');"
                                ></a>
                                <div>
                                  <table
                                    width="100%"
                                    border="0"
                                    cellpadding="3"
                                    cellspacing="0"
                                    class="border"
                                  >
                                    <tbody>
                                      <tr>
                                        <td>
                                          <strong>Lịch Xổ Số 07/02/2021</strong>
                                        </td>
                                        <td colspan="2" align="center">
                                          <strong
                                            >Thống kê tần suất xổ số</strong
                                          >
                                        </td>
                                        <td align="center">
                                          <strong>Gan cực đại</strong>
                                        </td>
                                        <td align="center">
                                          <strong>Thống kê Loto</strong>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a
                                            class="hottoday_mien"
                                            href="/xo-so-mien-nam.html"
                                            title="Xổ Số Miền Nam"
                                          >
                                            <strong>Xổ Số Miền Nam</strong>
                                          </a>
                                        </td>
                                        <td class="norightborder">
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-mien.html?mien=1&amp;lanquay=5&amp;tracuu=1&amp;dai1=1&amp;dai2=2"
                                            title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Miền Nam"
                                          >
                                            Tần suất Miền Nam
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-chi-tiet-mien.html?id=mien-nam"
                                            title="Thống kê tần suất chi tiết xổ số Miền Nam"
                                            target="_blank"
                                          >
                                            Chi tiết!..
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/gan-cuc-dai-mien.html?mien=1"
                                            title="Thống kê Gan Cực Đại Xổ Số Miền Nam"
                                          >
                                            GCĐ Miền Nam
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/lo-to-mien.html?mien=1&amp;dai1=1&amp;dai2=2"
                                            title="Thống Kê lô các cặp số của Xổ Số Miền Nam"
                                          >
                                            Xem Loto Miền Nam
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a
                                            class="hottoday_tinh22"
                                            href="/xo-so-mien-nam/tien-giang.html"
                                            title="Xổ Số Tiền Giang"
                                          >
                                            <span class="icon finnish"
                                              >Xổ Số Tiền Giang</span
                                            >
                                          </a>
                                        </td>
                                        <td class="norightborder">
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=22&amp;lanquay=5&amp;tracuu=1"
                                            title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Tiền Giang"
                                          >
                                            Tần suất Tiền Giang
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=tien-giang"
                                            title="Thống kê tần suất chi tiết xổ số  Tiền Giang "
                                            target="_blank"
                                          >
                                            Chi tiết!..
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=22"
                                            title="Thống kê Gan Cực Đại Xổ Số Tiền Giang "
                                          >
                                            GCĐ Tiền Giang
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/lo-to-tinh.html?tinh=22"
                                            title="Thống Kê lô các cặp số của Xổ Số Tiền Giang"
                                          >
                                            Xem Loto Tiền Giang
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a
                                            class="hottoday_tinh23"
                                            href="/xo-so-mien-nam/kien-giang.html"
                                            title="Xổ Số Kiên Giang"
                                          >
                                            <span class="icon finnish"
                                              >Xổ Số Kiên Giang</span
                                            >
                                          </a>
                                        </td>
                                        <td class="norightborder">
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=23&amp;lanquay=5&amp;tracuu=1"
                                            title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Kiên Giang"
                                          >
                                            Tần suất Kiên Giang
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=kien-giang"
                                            title="Thống kê tần suất chi tiết xổ số  Kiên Giang "
                                            target="_blank"
                                          >
                                            Chi tiết!..
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=23"
                                            title="Thống kê Gan Cực Đại Xổ Số Kiên Giang "
                                          >
                                            GCĐ Kiên Giang
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/lo-to-tinh.html?tinh=23"
                                            title="Thống Kê lô các cặp số của Xổ Số Kiên Giang"
                                          >
                                            Xem Loto Kiên Giang
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a
                                            class="hottoday_tinh24"
                                            href="/xo-so-mien-nam/da-lat.html"
                                            title="Xổ Số Đà Lạt"
                                          >
                                            <span class="icon running"
                                              >Xổ Số Đà Lạt</span
                                            >
                                          </a>
                                        </td>
                                        <td class="norightborder">
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=24&amp;lanquay=5&amp;tracuu=1"
                                            title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Đà Lạt"
                                          >
                                            Tần suất Đà Lạt
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=da-lat"
                                            title="Thống kê tần suất chi tiết xổ số  Đà Lạt "
                                            target="_blank"
                                          >
                                            Chi tiết!..
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=24"
                                            title="Thống kê Gan Cực Đại Xổ Số Đà Lạt "
                                          >
                                            GCĐ Đà Lạt
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/lo-to-tinh.html?tinh=24"
                                            title="Thống Kê lô các cặp số của Xổ Số Đà Lạt"
                                          >
                                            Xem Loto Đà Lạt
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a
                                            class="hottoday_mien"
                                            href="/xo-so-mien-bac.html"
                                            title="Xổ Số Miền Bắc"
                                          >
                                            <strong>Xổ Số Miền Bắc</strong>
                                          </a>
                                        </td>
                                        <td class="norightborder">
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-mien.html?mien=2&amp;lanquay=5&amp;tracuu=1&amp;dai1=1&amp;dai2=2"
                                            title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Miền Bắc"
                                          >
                                            Tần suất Miền Bắc
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-chi-tiet-mien.html?id=mien-bac"
                                            title="Thống kê tần suất chi tiết xổ số Miền Bắc"
                                            target="_blank"
                                          >
                                            Chi tiết!..
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/gan-cuc-dai-mien.html?mien=2"
                                            title="Thống kê Gan Cực Đại Xổ Số Miền Bắc"
                                          >
                                            GCĐ Miền Bắc
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/lo-to-mien.html?mien=2&amp;dai1=1&amp;dai2=2"
                                            title="Thống Kê lô các cặp số của Xổ Số Miền Bắc"
                                          >
                                            Xem Loto Miền Bắc
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a
                                            class="hottoday_tinh51"
                                            href="/xo-so-mien-bac/thai-binh.html"
                                            title="Xổ Số Thái Bình"
                                          >
                                            <span class="icon finnish"
                                              >Xổ Số Thái Bình</span
                                            >
                                          </a>
                                        </td>
                                        <td class="norightborder">
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=51&amp;lanquay=5&amp;tracuu=1"
                                            title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Thái Bình"
                                          >
                                            Tần suất Thái Bình
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=thai-binh"
                                            title="Thống kê tần suất chi tiết xổ số  Thái Bình "
                                            target="_blank"
                                          >
                                            Chi tiết!..
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=51"
                                            title="Thống kê Gan Cực Đại Xổ Số Thái Bình "
                                          >
                                            GCĐ Thái Bình
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/lo-to-tinh.html?tinh=51"
                                            title="Thống Kê lô các cặp số của Xổ Số Thái Bình"
                                          >
                                            Xem Loto Thái Bình
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a
                                            class="hottoday_mien"
                                            href="/xo-so-mien-trung.html"
                                            title="Xổ Số Miền Trung"
                                          >
                                            <strong>Xổ Số Miền Trung</strong>
                                          </a>
                                        </td>
                                        <td class="norightborder">
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-mien.html?mien=3&amp;lanquay=5&amp;tracuu=1&amp;dai1=1&amp;dai2=2"
                                            title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Miền Trung"
                                          >
                                            Tần suất Miền Trung
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-chi-tiet-mien.html?id=mien-trung"
                                            title="Thống kê tần suất chi tiết xổ số Miền Trung"
                                            target="_blank"
                                          >
                                            Chi tiết!..
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/gan-cuc-dai-mien.html?mien=3"
                                            title="Thống kê Gan Cực Đại Xổ Số Miền Trung"
                                          >
                                            GCĐ Miền Trung
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/lo-to-mien.html?mien=3&amp;dai1=1&amp;dai2=2"
                                            title="Thống Kê lô các cặp số của Xổ Số Miền Trung"
                                          >
                                            Xem Loto Miền Trung
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a
                                            class="hottoday_tinh39"
                                            href="/xo-so-mien-trung/kon-tum.html"
                                            title="Xổ Số Kon Tum"
                                          >
                                            <span class="icon finnish"
                                              >Xổ Số Kon Tum</span
                                            >
                                          </a>
                                        </td>
                                        <td class="norightborder">
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=39&amp;lanquay=5&amp;tracuu=1"
                                            title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Kon Tum"
                                          >
                                            Tần suất Kon Tum
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=kon-tum"
                                            title="Thống kê tần suất chi tiết xổ số  Kon Tum "
                                            target="_blank"
                                          >
                                            Chi tiết!..
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=39"
                                            title="Thống kê Gan Cực Đại Xổ Số Kon Tum "
                                          >
                                            GCĐ Kon Tum
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/lo-to-tinh.html?tinh=39"
                                            title="Thống Kê lô các cặp số của Xổ Số Kon Tum"
                                          >
                                            Xem Loto Kon Tum
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <a
                                            class="hottoday_tinh31"
                                            href="/xo-so-mien-trung/khanh-hoa.html"
                                            title="Xổ Số Khánh Hòa"
                                            ><span class="icon finnish"
                                              >Xổ Số Khánh Hòa</span
                                            >
                                          </a>
                                        </td>
                                        <td class="norightborder">
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-tinh.html?tinh=31&amp;lanquay=5&amp;tracuu=1"
                                            title="Thống kê tần suất xuất hiện các cặp số của Xổ Số Khánh Hòa"
                                            >Tần suất Khánh Hòa
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html?id=khanh-hoa"
                                            title="Thống kê tần suất chi tiết xổ số  Khánh Hòa "
                                            target="_blank"
                                            >Chi tiết!..
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/gan-cuc-dai-tinh.html?tinh=31"
                                            title="Thống kê Gan Cực Đại Xổ Số Khánh Hòa "
                                            >GCĐ Khánh Hòa
                                          </a>
                                        </td>
                                        <td>
                                          <a
                                            href="/thong-ke-xo-so/lo-to-tinh.html?tinh=31"
                                            title="Thống Kê lô các cặp số của Xổ Số Khánh Hòa"
                                            >Xem Loto Khánh Hòa
                                          </a>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="clear"></div>
                            </div>
                            <div>
                              <ul
                                id="adv1"
                                style="
                                  margin: 0px auto;
                                  padding: 0px;
                                  width: 520px;
                                  height: 70px;
                                  overflow: hidden;
                                "
                              >
                                <li id="adv1_10781_0" style="opacity: 1">
                                  <a
                                    href="/thong-tin/doi-so-trung.html"
                                    target="_blank"
                                  >
                                    <img
                                      src="//img.minhngoc.net/doisotrung-520x70-06.gif"
                                      border="0"
                                      width="520"
                                      height="70"
                                    />
                                  </a>
                                </li>
                                <li
                                  id="adv1_10781_1"
                                  style="opacity: 1; display: none"
                                >
                                  <a
                                    href="/thong-tin/doi-so-trung.html"
                                    target="_blank"
                                  >
                                    <img
                                      src="//img.minhngoc.net/doisotrung-520x70-02.gif"
                                      border="0"
                                      width="520"
                                      height="70"
                                    />
                                  </a>
                                </li>
                              </ul>
                              <div class="clear"></div>
                            </div>
                          </div>
                          <div class="mainbody" v-if="startSpinner">
                            <!-- //: LotterySpin -->
                            <LotterySpin
                              :time="time"
                              :textTitle="textTitle"
                              :date="date"
                              :numberBall="numberBall"
                              :headText="headText"
                              :number="number"
                            />
                          </div>
                          <div class="mainbody" v-else>
                            <!-- //: LotteryShow -->
                            <LotteryShow
                              :time="time"
                              :textTitle="textTitle"
                              :date="date"
                              :numberBall="numberBall"
                              :headText="headText"
                              :number="number"
                            />
                          </div>
                        </td>
                        <td class="right" style="height: auto !important">
                          <div id="dnw_right" style="height: auto !important">
                            <div style="height: auto !important">
                              <div>
                                <img
                                  alt=""
                                  src="@/assets/ads2.png"
                                  border="0"
                                  height="600"
                                />
                                <div class="clear"></div>
                              </div>
                              <div>
                                <div class="modulesLR moduleBrown">
                                  <div class="body-l">
                                    <div class="body-r">
                                      <ul class="list_news">
                                        <li>
                                          <a
                                            href="/tin-tuc/47-thay-doi-gio-mo-thuong-xo-so-mien-bac.html "
                                            >Thay đổi giờ mở thưởng Xổ số Miền
                                            Bắc</a
                                          >
                                        </li>
                                        <li>
                                          <a
                                            href="/tin-tuc/35-khuyen-cao-khi-doi-so-trung.html "
                                            >KHUYẾN CÁO KHI ĐỔI SỐ TRÚNG</a
                                          >
                                        </li>
                                        <li>
                                          <a
                                            href="/tin-tuc/57-thue-thu-nhap-trung-xo-so-co-cau-giai-thuong-tu-01-01-2017.html "
                                            >Thuế thu nhập trúng xổ số &amp; cơ
                                            cấu giải thưởng từ 01/01/2017</a
                                          >
                                        </li>
                                        <li>
                                          <a
                                            href="/tin-tuc/46-doi-so-trung-dac-biet-o-dau-thu-tuc-nhu-the-nao-minh-ngoc-tp-hcm.html "
                                            >ĐỔI SỐ TRÚNG ĐẶC BIỆT Ở ĐÂU &amp;
                                            THỦ TỤC NHƯ THẾ NÀO ?... MINH NGỌC
                                            TP HCM</a
                                          >
                                        </li>
                                        <li>
                                          <a
                                            href="/tin-tuc/50-nghi-dinh-78-2012-nd-cp-ve-kinh-doanh-xo-so.html "
                                            >Nghị định 78/2012/NĐ-CP về kinh
                                            doanh xổ số</a
                                          >
                                        </li>
                                        <li>
                                          <a
                                            href="/tin-tuc/23-muc-chi-hoa-hong-dai-ly-xo-so.html "
                                            >Mức chi hoa hồng đại lý xổ số</a
                                          >
                                        </li>
                                        <ul></ul>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div class="clear"></div>
                              </div>
                              <div>
                                <img
                                  alt=""
                                  src="@/assets/ads3.png"
                                  border="0"
                                  height="600"
                                />
                                <div class="clear"></div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="parent"
                            style="
                              position: relative;
                              overflow: hidden;
                              height: auto;
                            "
                          >
                            <div
                              id="sidebar_right"
                              style="
                                position: relative;
                                top: 0px;
                                padding-top: 0px;
                              "
                            >
                              <div>
                                <div>
                                  <!-- 300_minhngoc.net.vn-3 -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import cookies from "js-cookie";
import LotterySpin from "../components/LotterySpin";
import LotteryShow from "../components/LotteryShow";

export default {
  components: {
    LotterySpin,
    LotteryShow,
  },
  data() {
    return {
      startSpinner: false,
      time: "17:00",
      textTitle: "Hà Nội SVip",
      date: null,
      numberBall: {
        one: {
          one: null,
          two: null,
          three: null,
          four: null,
          five: null,
          six: null,
        },
        two: {
          one: null,
          two: null,
          three: null,
          four: null,
          five: null,
          six: null,
        },
        three: {
          one: null,
          two: null,
          three: null,
          four: null,
        },
      },
      headText: {
        one: null,
        two: null,
        three: null,
        four: null,
        five: null,
        six: null,
      },
      number: {
        one: {
          one: null,
        },
        two: {
          one: null,
        },
        three: {
          one: null,
          two: null,
        },
        four: {
          one: null,
          two: null,
          three: null,
          four: null,
          five: null,
          six: null,
        },
        five: {
          one: null,
          two: null,
          three: null,
          four: null,
        },
        six: {
          one: null,
          two: null,
          three: null,
          four: null,
          five: null,
          six: null,
        },
        seven: {
          one: null,
          two: null,
          three: null,
        },
        eight: {
          one: null,
          two: null,
          three: null,
          four: null,
        },
      },
    };
  },
  async mounted() {
    await this.getCheckSpinner();
    await window.setInterval(async () => {
      var date = new Date();
      if (
        date.getHours() >= this.startHour &&
        date.getHours() <= this.endHour
      ) {
        await this.getCheckSpinner();
      }
    }, 3000);
  },
  methods: {
    async getCheckSpinner() {
      var formData = {
        lottery_name: this.lotteryName,
      };
      // eslint-disable-next-line no-undef
      await axios
        .post(this.baseUrl + "check-spin", formData)
        .then(async (res) => {
          this.startSpinner = res.data.start_spin;
          cookies.set("diff", res.data.diff);
          if (res.data.start_spin) {
            await this.getLotterySpin();
            await this.cookiesLotterySpin();
          } else {
            await this.getLotteryShow();
          }
        });
    },
    async getLotterySpin() {
      var formData = {
        lottery_name: this.lotteryName,
      };
      // eslint-disable-next-line no-undef
      await axios
        .post(this.baseUrl + "lottery-spin", formData)
        .then(async (res) => {
          // console.log(res.data)
          this.date = res.data.date;
          cookies.set(
            "example_number_ball",
            res.data.lottery_date.example_number_ball
          );
          cookies.set(
            "example_number_text",
            res.data.lottery_date.example_number_text
          );
          cookies.set("example_number", res.data.lottery_date.example_number);
          var diff = cookies.get("diff");
          if (diff <= 0) {
            cookies.set("number_one", res.data.lottery_numbers[0].number);
          }
          if (diff <= 15) {
            cookies.set("number_two", res.data.lottery_numbers[1].number);
          }
        });
    },
    cookiesLotterySpin() {
      var example_number_ball = JSON.parse(cookies.get("example_number_ball"));
      var example_number_text = JSON.parse(cookies.get("example_number_text"));
      var example_number = JSON.parse(cookies.get("example_number"));
      var number_one =
        cookies.get("number_one") != "null" ? cookies.get("number_one") : true;
      var number_two =
        cookies.get("number_two") != "null" ? cookies.get("number_two") : true;
      var diff = cookies.get("diff") - 0.3;
      // console.log(diff);

      // Kết quả xổ số điện toán 1*2*3
      if (diff <= 29) {
        this.numberBall.one.one = example_number_ball.one[0];
      }
      if (diff <= 28) {
        this.numberBall.one.two = example_number_ball.one[1];
      }
      if (diff <= 27) {
        this.numberBall.one.three = example_number_ball.one[2];
      }
      if (diff <= 26) {
        this.numberBall.one.four = example_number_ball.one[3];
      }
      if (diff <= 25) {
        this.numberBall.one.five = example_number_ball.one[4];
      }
      if (diff <= 24) {
        this.numberBall.one.six = example_number_ball.one[5];
      }

      // Kết quả xổ số điện toán 6X36
      if (diff <= 23.5) {
        this.numberBall.two.one = example_number_ball.two[0];
      }
      if (diff <= 23) {
        this.numberBall.two.two = example_number_ball.two[1];
      }
      if (diff <= 22.5) {
        this.numberBall.two.three = example_number_ball.two[2];
      }
      if (diff <= 22) {
        this.numberBall.two.four = example_number_ball.two[3];
      }
      if (diff <= 21.5) {
        this.numberBall.two.five = example_number_ball.two[4];
      }
      if (diff <= 21) {
        this.numberBall.two.six = example_number_ball.two[5];
      }

      // Kết quả xổ số Thần Tài 4
      if (diff <= 20.5) {
        this.numberBall.three.one = example_number_ball.three[0];
      }
      if (diff <= 20) {
        this.numberBall.three.two = example_number_ball.three[1];
      }
      if (diff <= 19.5) {
        this.numberBall.three.three = example_number_ball.three[2];
      }
      if (diff <= 19) {
        this.numberBall.three.four = example_number_ball.three[3];
      }

      // // Thứ Bảy
      if (diff <= 18.75) {
        this.headText.one = example_number_text.one;
      }
      if (diff <= 18.25) {
        this.headText.two = example_number_text.two;
      }
      if (diff <= 17.75) {
        this.headText.three = example_number_text.three;
      }
      if (diff <= 17.25) {
        this.headText.four = example_number_text.four;
      }
      if (diff <= 16.75) {
        this.headText.five = example_number_text.five;
      }
      if (diff <= 16.25) {
        this.headText.six = example_number_text.six;
      }

      // Giải ĐB
      if (diff <= 2) {
        if (diff > 0 && diff <= 2) {
          this.number.one.one = true;
        } else {
          this.number.one.one = number_one;
        }
      }

      // Giải nhất
      if (diff <= 16) {
        if (diff >= 15 && diff < 16) {
          this.number.two.one = true;
        } else {
          this.number.two.one = number_two;
        }
      }

      // Giải nhì
      if (diff <= 15) {
        if (diff > 14.5 && diff <= 15) {
          this.number.three.one = true;
        } else {
          this.number.three.one = example_number.five_one[0];
        }
      }
      if (diff <= 15) {
        if (diff > 14 && diff <= 15) {
          this.number.three.two = true;
        } else {
          this.number.three.two = example_number.five_one[1];
        }
      }

      // Giải ba
      if (diff <= 14) {
        if (diff > 13.5 && diff <= 14) {
          this.number.four.one = true;
        } else {
          this.number.four.one = example_number.five_two[0];
        }
      }
      if (diff <= 14) {
        if (diff > 13 && diff <= 14) {
          this.number.four.two = true;
        } else {
          this.number.four.two = example_number.five_two[1];
        }
      }
      if (diff <= 14) {
        if (diff > 12.5 && diff <= 14) {
          this.number.four.two = true;
        } else {
          this.number.four.two = example_number.five_two[1];
        }
      }
      if (diff <= 14) {
        if (diff > 12 && diff <= 14) {
          this.number.four.three = true;
        } else {
          this.number.four.three = example_number.five_two[2];
        }
      }
      if (diff <= 12.5) {
        if (diff > 11.5 && diff <= 12.5) {
          this.number.four.four = true;
        } else {
          this.number.four.four = example_number.five_two[3];
        }
      }
      if (diff <= 12.5) {
        if (diff > 11 && diff <= 12.5) {
          this.number.four.five = true;
        } else {
          this.number.four.five = example_number.five_two[4];
        }
      }
      if (diff <= 12.5) {
        if (diff > 10.5 && diff <= 12.5) {
          this.number.four.six = true;
        } else {
          this.number.four.six = example_number.five_two[5];
        }
      }

      // Giải tư
      if (diff <= 10.5) {
        if (diff > 10 && diff <= 10.5) {
          this.number.five.one = true;
        } else {
          this.number.five.one = example_number.four_one[0];
        }
      }
      if (diff <= 10) {
        if (diff > 9.5 && diff <= 10) {
          this.number.five.two = true;
        } else {
          this.number.five.two = example_number.four_one[1];
        }
      }
      if (diff <= 9.5) {
        if (diff > 9 && diff <= 9.5) {
          this.number.five.three = true;
        } else {
          this.number.five.three = example_number.four_one[2];
        }
      }
      if (diff <= 9) {
        if (diff > 8.5 && diff <= 9) {
          this.number.five.four = true;
        } else {
          this.number.five.four = example_number.four_one[3];
        }
      }

      // Giải năm
      if (diff <= 8.5) {
        if (diff > 8 && diff <= 8.5) {
          this.number.six.one = true;
        } else {
          this.number.six.one = example_number.four_two[0];
        }
      }
      if (diff <= 8.5) {
        if (diff > 7.5 && diff <= 8.5) {
          this.number.six.two = true;
        } else {
          this.number.six.two = example_number.four_two[1];
        }
      }
      if (diff <= 8.5) {
        if (diff > 7 && diff <= 8.5) {
          this.number.six.three = true;
        } else {
          this.number.six.three = example_number.four_two[2];
        }
      }
      if (diff <= 7) {
        if (diff > 6.5 && diff <= 7) {
          this.number.six.four = true;
        } else {
          this.number.six.four = example_number.four_two[3];
        }
      }
      if (diff <= 7) {
        if (diff > 6 && diff <= 7) {
          this.number.six.five = true;
        } else {
          this.number.six.five = example_number.four_two[4];
        }
      }
      if (diff <= 7) {
        if (diff > 5.5 && diff <= 7) {
          this.number.six.six = true;
        } else {
          this.number.six.six = example_number.four_two[5];
        }
      }

      // Giải sáu
      if (diff <= 5.5) {
        if (diff > 5 && diff <= 5.5) {
          this.number.seven.one = true;
        } else {
          this.number.seven.one = example_number.three_one[0];
        }
      }
      if (diff <= 5.5) {
        if (diff > 4.5 && diff <= 5.5) {
          this.number.seven.two = true;
        } else {
          this.number.seven.two = example_number.three_one[1];
        }
      }
      if (diff <= 5.5) {
        if (diff > 4 && diff <= 5.5) {
          this.number.seven.three = true;
        } else {
          this.number.seven.three = example_number.three_one[2];
        }
      }

      // Giải bảy
      if (diff <= 4) {
        if (diff > 3.5 && diff <= 4) {
          this.number.eight.one = true;
        } else {
          this.number.eight.one = example_number.two_one[0];
        }
      }
      if (diff <= 4) {
        if (diff > 3 && diff <= 4) {
          this.number.eight.two = true;
        } else {
          this.number.eight.two = example_number.two_one[1];
        }
      }
      if (diff <= 4) {
        if (diff > 2.5 && diff <= 4) {
          this.number.eight.three = true;
        } else {
          this.number.eight.three = example_number.two_one[2];
        }
      }
      if (diff <= 4) {
        if (diff > 2 && diff <= 4) {
          this.number.eight.four = true;
        } else {
          this.number.eight.four = example_number.two_one[3];
        }
      }
    },
    async getLotteryShow() {
      var formData = {
        lottery_name: this.lotteryName,
      };
      // eslint-disable-next-line no-undef
      await axios
        .post(this.baseUrl + "lottery-show", formData)
        .then(async (res) => {
          var example_number_ball = JSON.parse(
            res.data.lottery_date.example_number_ball
          );
          var example_number_text = JSON.parse(
            res.data.lottery_date.example_number_text
          );
          var example_number = JSON.parse(res.data.lottery_date.example_number);
          this.date = res.data.date;
          // Kết quả xổ số điện toán 1*2*3
          this.numberBall.one.one = example_number_ball.one[0];
          this.numberBall.one.two = example_number_ball.one[1];
          this.numberBall.one.three = example_number_ball.one[2];
          this.numberBall.one.four = example_number_ball.one[3];
          this.numberBall.one.five = example_number_ball.one[4];
          this.numberBall.one.six = example_number_ball.one[5];
          // Kết quả xổ số điện toán 6X36
          this.numberBall.two.one = example_number_ball.two[0];
          this.numberBall.two.two = example_number_ball.two[1];
          this.numberBall.two.three = example_number_ball.two[2];
          this.numberBall.two.four = example_number_ball.two[3];
          this.numberBall.two.five = example_number_ball.two[4];
          this.numberBall.two.six = example_number_ball.two[5];
          // Kết quả xổ số Thần Tài 4
          this.numberBall.three.one = example_number_ball.three[0];
          this.numberBall.three.two = example_number_ball.three[1];
          this.numberBall.three.three = example_number_ball.three[2];
          this.numberBall.three.four = example_number_ball.three[3];
          // Thứ Bảy
          this.headText.one = example_number_text.one;
          this.headText.two = example_number_text.two;
          this.headText.three = example_number_text.three;
          this.headText.four = example_number_text.four;
          this.headText.five = example_number_text.five;
          this.headText.six = example_number_text.six;
          // Giải ĐB
          this.number.one.one = res.data.lottery_numbers[0].number;
          // Giải nhất
          this.number.two.one = res.data.lottery_numbers[1].number;
          // Giải nhì
          this.number.three.one = example_number.five_one[0];
          this.number.three.two = example_number.five_one[1];
          // Giải ba
          this.number.four.one = example_number.five_two[0];
          this.number.four.two = example_number.five_two[1];
          this.number.four.three = example_number.five_two[2];
          this.number.four.four = example_number.five_two[3];
          this.number.four.five = example_number.five_two[4];
          this.number.four.six = example_number.five_two[5];
          // Giải tư
          this.number.five.one = example_number.four_one[0];
          this.number.five.two = example_number.four_one[1];
          this.number.five.three = example_number.four_one[2];
          this.number.five.four = example_number.four_one[3];
          // Giải năm
          this.number.six.one = example_number.four_two[0];
          this.number.six.two = example_number.four_two[1];
          this.number.six.three = example_number.four_two[2];
          this.number.six.four = example_number.four_two[3];
          this.number.six.five = example_number.four_two[4];
          this.number.six.six = example_number.four_two[5];
          // Giải sáu
          this.number.seven.one = example_number.three_one[0];
          this.number.seven.two = example_number.three_one[1];
          this.number.seven.three = example_number.three_one[2];
          // Giải bảy
          this.number.eight.one = example_number.two_one[0];
          this.number.eight.two = example_number.two_one[1];
          this.number.eight.three = example_number.two_one[2];
          this.number.eight.four = example_number.two_one[3];
        });
    },
  },
};
</script>