<template>
  <div id="noidung">
    <div id="ffffffff"></div>
    <h1 class="pagetitle">TRỰC TIẾP XỔ SỐ Miền Bắc {{ date }}</h1>
    <div></div>
    <div>
      <div id="sound"></div>
      <div id="hinhxstt">
        <div id="kqxshn">
          <img src="@/assets/xshome.gif" border="0" align="absmiddle" />
          <h1 class="ttxstt_waitting">
            <a href="/ket-qua-xo-so/mien-bac.html"
              >Xổ số Miền Bắc đã kết thúc » Xem thêm kết quả xổ số theo
              ngày!...</a
            >
          </h1>
        </div>
      </div>
      <div id="ttkqxs" align="center">
        <center>
          <div id="box_tructiepkqxs" style="position: relative">
            <div id="tab_xstt">
              <a href="mien-nam.html">Miền Nam</a>
              <a href="mien-trung.html">Miền Trung</a>
              <a href="mien-bac.html" class="_active"
                >Miền Bắc &amp; Điện Toán</a
              >
            </div>
            <div id="boxtructiepdientoan" class="boxkqxsdientoan">
              <div class="title-l">
                <div class="title-r">
                  <h1>
                    Trực tiếp kết quả xổ số điện toán ngày: {{ date }}
                    <div class="livestream_lott"><a></a><span></span></div>
                    <div></div>
                    <small>
                      <i class="green"
                        >Tường thuật trực tiếp vào lúc {{ time }}:00</i
                      >
                    </small>
                  </h1>
                </div>
              </div>
              <div class="body-l">
                <div class="body-r">
                  <div>
                    <div class="bangkq6x36 bkq123">
                      <div class="title">
                        <table
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td class="title">
                                <a href="/xo-so-dien-toan/1*2*3.html"
                                  >Kết quả xổ số điện toán 1*2*3</a
                                >
                              </td>
                              <td align="right" class="mothuong">
                                <a
                                  class="viewmore"
                                  href="/xo-so-dien-toan/1*2*3.html"
                                  target="_blank"
                                >
                                  <img
                                    align="absMiddle"
                                    alt=""
                                    border="0"
                                    hspace="5"
                                    src="@/assets/viewmore.png"
                                  />Xem thêm
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="content">
                        <div class="lotto-result">
                          <div id="result-games">
                            <div class="box-result-detail">
                              <ul class="result-number">
                                <li>
                                  <div id="DT123_G1" class="finnish1 bool">
                                    {{
                                      numberBall.one.one != null
                                        ? numberBall.one.one
                                        : numberCountDownPosition.one
                                    }}
                                  </div>
                                </li>
                                <li class="ngancach"></li>
                                <li></li>
                                <li>
                                  <div id="DT123_G2_1" class="finnish2 bool">
                                    {{
                                      numberBall.one.two != null
                                        ? numberBall.one.two
                                        : numberCountDownPosition.two
                                    }}
                                  </div>
                                </li>
                                <li></li>
                                <li>
                                  <div id="DT123_G2_2" class="finnish3 bool">
                                    {{
                                      numberBall.one.three != null
                                        ? numberBall.one.three
                                        : numberCountDownPosition.three
                                    }}
                                  </div>
                                </li>
                                <li class="ngancach"></li>
                                <li></li>
                                <li>
                                  <div id="DT123_G3_1" class="finnish4 bool">
                                    {{
                                      numberBall.one.four != null
                                        ? numberBall.one.four
                                        : numberCountDownPosition.four
                                    }}
                                  </div>
                                </li>
                                <li></li>
                                <li>
                                  <div id="DT123_G3_2" class="finnish5 bool">
                                    {{
                                      numberBall.one.five != null
                                        ? numberBall.one.five
                                        : numberCountDownPosition.five
                                    }}
                                  </div>
                                </li>
                                <li></li>
                                <li>
                                  <div id="DT123_G3_3" class="finnish6 bool">
                                    {{
                                      numberBall.one.six != null
                                        ? numberBall.one.six
                                        : numberCountDownPosition.two
                                    }}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bangkq6x36">
                      <div class="title">
                        <table
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td class="title">
                                <a href="/xo-so-dien-toan/6x36.html"
                                  >Kết quả xổ số điện toán 6X36</a
                                >
                              </td>
                              <td align="right" class="mothuong">
                                <a
                                  class="viewmore"
                                  href="/xo-so-dien-toan/6x36.html"
                                  target="_blank"
                                >
                                  <img
                                    align="absMiddle"
                                    alt=""
                                    border="0"
                                    hspace="5"
                                    src="@/assets/viewmore.png"
                                  />Xem thêm
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="content">
                        <div class="lotto-result">
                          <div id="result-games">
                            <div class="box-result-detail">
                              <ul class="result-number">
                                <li>
                                  <div id="DT6x36_G1" class="finnish1 bool">
                                    {{
                                      numberBall.two.one != null
                                        ? numberBall.two.one
                                        : numberCountDownPosition.two +
                                          "" +
                                          numberCountDownPosition.one
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div id="DT6x36_G2" class="finnish2 bool">
                                    {{
                                      numberBall.two.two != null
                                        ? numberBall.two.two
                                        : numberCountDownPosition.four +
                                          "" +
                                          numberCountDownPosition.five
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div id="DT6x36_G3" class="finnish3 bool">
                                    {{
                                      numberBall.two.three != null
                                        ? numberBall.two.three
                                        : numberCountDownPosition.one +
                                          "" +
                                          numberCountDownPosition.three
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div id="DT6x36_G4" class="finnish4 bool">
                                    {{
                                      numberBall.two.four != null
                                        ? numberBall.two.four
                                        : numberCountDownPosition.five +
                                          "" +
                                          numberCountDownPosition.four
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div id="DT6x36_G5" class="finnish5 bool">
                                    {{
                                      numberBall.two.five != null
                                        ? numberBall.two.five
                                        : numberCountDownPosition.three +
                                          "" +
                                          numberCountDownPosition.two
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div id="DT6x36_G6" class="finnish6 bool">
                                    {{
                                      numberBall.two.six != null
                                        ? numberBall.two.six
                                        : numberCountDownPosition.two +
                                          "" +
                                          numberCountDownPosition.five
                                    }}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="bangkq6x36 bkqtt4">
                      <div class="title">
                        <table
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          width="100%"
                        >
                          <tbody>
                            <tr>
                              <td class="title">
                                <a href="/xo-so-dien-toan/than-tai-4.html"
                                  >Kết quả xổ số Thần Tài 4</a
                                >
                              </td>
                              <td align="right" class="mothuong">
                                <a
                                  class="viewmore"
                                  href="/xo-so-dien-toan/than-tai-4.html"
                                >
                                  <img
                                    align="absMiddle"
                                    alt=""
                                    border="0"
                                    hspace="5"
                                    src="@/assets/viewmore.png"
                                  />Xem thêm
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="content">
                        <div class="lotto-result">
                          <div id="result-games">
                            <div class="box-result-detail">
                              <ul class="result-number">
                                <li>
                                  <div id="DTtt4_G1_1" class="finnish1 bool">
                                    {{
                                      numberBall.three.one != null
                                        ? numberBall.three.one
                                        : numberCountDownPosition.five
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div id="DTtt4_G1_2" class="finnish2 bool">
                                    {{
                                      numberBall.three.two != null
                                        ? numberBall.three.two
                                        : numberCountDownPosition.three
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div id="DTtt4_G1_3" class="finnish3 bool">
                                    {{
                                      numberBall.three.three != null
                                        ? numberBall.three.three
                                        : numberCountDownPosition.two
                                    }}
                                  </div>
                                </li>
                                <li>
                                  <div id="DTtt4_G1_4" class="finnish4 bool">
                                    {{
                                      numberBall.three.four != null
                                        ? numberBall.three.four
                                        : numberCountDownPosition.four
                                    }}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
            <div style="clear: both; height: 0px"></div>
            <div class="box_kqxs box_kqxstt_mienbac">
              <div class="top">
                <div class="bkl">
                  <div class="bkr">
                    <div class="bkm">
                      <div class="icon">
                        <div class="icon_new"></div>
                      </div>
                      <div class="title">
                        TRỰC TIẾP XỔ SỐ Miền Bắc - {{ date }}
                      </div>
                      <div class="iconright">
                        <a
                          class="icontk"
                          title="Xem thống kê chi tiết xổ số Miền Bắc"
                          target="_blank"
                          href="/thong-ke-xo-so/tan-suat-chi-tiet-mien.html?id=mien-bac"
                        >
                          <div></div>
                        </a>
                      </div>
                      <div class="iconrightkq">
                        <a
                          class="iconkq"
                          title="Xem thêm các kết quả xổ số Miền Bắc"
                          target="_blank"
                          href="../ket-qua-xo-so/mien-bac.html"
                        >
                          <div>Xem thêm!...</div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="content">
                <div class="bkqmienbac">
                  <table
                    width="100%"
                    border="0"
                    cellspacing="0"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td valign="top" width="100%">
                          <table
                            border="0"
                            cellpadding="0"
                            cellspacing="0"
                            class="bkqtinhmienbac bangketquaSo bangketquaSo_0"
                            width="100%"
                            data="0"
                          >
                            <tbody>
                              <tr
                                v-bind:class="[lotteryHead ? 'giaiActive' : '']"
                                @mouseover="mouseOver(true, 0)"
                                @mouseleave="mouseOver(false, 0)"
                              >
                                <td class="thu" width="65">
                                  <a
                                    href="/ket-qua-xo-so/mien-bac/thu-bay.html"
                                    title="Click xem tất cả KQXS Miền Bắc ngày Thứ Bảy"
                                    >Thứ Bảy</a
                                  >
                                </td>
                                <td class="ngay">
                                  <span class="tngay">Ngày: {{ date }}</span>
                                  <div
                                    class="loai_ve"
                                    style="
                                      margin-left: 10px;
                                      font-style: normal;
                                      font-weight: normal;
                                      display: block;
                                    "
                                  >
                                    <a
                                      href="/thong-tin/co-cau-giai-thuong-xo-so-mien-bac.html"
                                      style="color: #000; margin-left: 10px"
                                      target="_blank"
                                    >
                                      Ký hiệu trúng Đặc Biệt:
                                    </a>
                                    <span id="loaive_50" class="">
                                      <span
                                        >{{
                                          headText.one != null
                                            ? headText.one + "HN"
                                            : numberCountDownPosition.one +
                                              "" +
                                              numberCountDownPosition.two +
                                              "" +
                                              stringRandom.one
                                        }}-</span
                                      >
                                      <span
                                        >{{
                                          headText.two != null
                                            ? headText.two + "HN"
                                            : numberCountDownPosition.four +
                                              "" +
                                              numberCountDownPosition.three +
                                              "" +
                                              stringRandom.two
                                        }}-</span
                                      >
                                      <span
                                        >{{
                                          headText.three != null
                                            ? headText.three + "HN"
                                            : numberCountDownPosition.five +
                                              "" +
                                              numberCountDownPosition.one +
                                              "" +
                                              stringRandom.three
                                        }}-</span
                                      >
                                      <span
                                        >{{
                                          headText.four != null
                                            ? headText.four + "HN"
                                            : numberCountDownPosition.two +
                                              "" +
                                              numberCountDownPosition.four +
                                              "" +
                                              stringRandom.four
                                        }}-</span
                                      >
                                      <span
                                        >{{
                                          headText.five != null
                                            ? headText.five + "HN"
                                            : numberCountDownPosition.three +
                                              "" +
                                              numberCountDownPosition.one +
                                              "" +
                                              stringRandom.five
                                        }}-</span
                                      >
                                      <span>{{
                                        headText.six != null
                                          ? headText.six + "HN"
                                          : numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.five +
                                            "" +
                                            stringRandom.six
                                      }}</span>
                                    </span>
                                    <span class="tinh_loai_ve">
                                      <a
                                        href="../xo-so-mien-bac/nam-dinh.html"
                                        >{{ textTitle }}</a
                                      >
                                    </span>
                                  </div>
                                  <span class="phathanh">
                                    Xổ số
                                    <span class="tentinhs">
                                      <a
                                        href="../xo-so-mien-bac/nam-dinh.html"
                                        >{{ textTitle }}</a
                                      >
                                      - XSMB
                                    </span>
                                  </span>
                                </td>
                              </tr>
                              <tr
                                v-bind:class="[lottery.one ? 'giaiActive' : '']"
                              >
                                <td
                                  class="giaidbl"
                                  v-bind:class="[
                                    lottery.one ? 'giaiActive' : '',
                                  ]"
                                  @mouseover="mouseOver(true, 1)"
                                  @mouseleave="mouseOver(false, 1)"
                                >
                                  <a
                                    href=" /thong-tin/co-cau-giai-thuong-mien-bac.html"
                                    target="_blank"
                                    >Giải ĐB</a
                                  >
                                </td>
                                <td class="giaidb">
                                  <div id="giaidb_50" class="giaiSo">
                                    <template v-if="number.one.one == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.one.one == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.five
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.one.one }}
                                      </template>
                                    </template>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                v-bind:class="[lottery.two ? 'giaiActive' : '']"
                              >
                                <td
                                  class="giai1l"
                                  v-bind:class="[
                                    lottery.two ? 'giaiActive' : '',
                                  ]"
                                  @mouseover="mouseOver(true, 2)"
                                  @mouseleave="mouseOver(false, 2)"
                                >
                                  Giải nhất
                                </td>
                                <td class="giai1">
                                  <div id="giai1_50" class="giaiSo">
                                    <template v-if="number.two.one == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.two.one == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.two
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.two.one }}
                                      </template>
                                    </template>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                v-bind:class="[
                                  lottery.three ? 'giaiActive' : '',
                                ]"
                              >
                                <td
                                  class="giai2l"
                                  v-bind:class="[
                                    lottery.three ? 'giaiActive' : '',
                                  ]"
                                  @mouseover="mouseOver(true, 3)"
                                  @mouseleave="mouseOver(false, 3)"
                                >
                                  Giải nhì
                                </td>
                                <td class="giai2">
                                  <div id="giai2_1_50" class="giaiSo">
                                    <template v-if="number.three.one == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.three.one == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.two
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.three.one }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai2_2_50" class="giaiSo">
                                    <template v-if="number.three.two == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.three.two == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.two
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.three.two }}
                                      </template>
                                    </template>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                v-bind:class="[
                                  lottery.four ? 'giaiActive' : '',
                                ]"
                              >
                                <td
                                  class="giai3l"
                                  v-bind:class="[
                                    lottery.four ? 'giaiActive' : '',
                                  ]"
                                  @mouseover="mouseOver(true, 4)"
                                  @mouseleave="mouseOver(false, 4)"
                                >
                                  Giải ba
                                </td>
                                <td class="giai3">
                                  <div id="giai3_1_50" class="giaiSo">
                                    <template v-if="number.four.one == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.four.one == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.two
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.four.one }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai3_2_50" class="giaiSo">
                                    <template v-if="number.four.two == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.four.two == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.five
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.four.two }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai3_3_50" class="giaiSo">
                                    <template v-if="number.four.three == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template
                                        v-if="number.four.three == true"
                                      >
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.two
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.four.three }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai3_4_50" class="giaiSo">
                                    <template v-if="number.four.four == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.four.four == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.one
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.four.four }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai3_5_50" class="giaiSo">
                                    <template v-if="number.four.five == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.four.five == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.four
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.four.five }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai3_6_50" class="giaiSo">
                                    <template v-if="number.four.six == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.four.six == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.five
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.four.six }}
                                      </template>
                                    </template>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                v-bind:class="[
                                  lottery.five ? 'giaiActive' : '',
                                ]"
                              >
                                <td
                                  class="giai4l"
                                  v-bind:class="[
                                    lottery.five ? 'giaiActive' : '',
                                  ]"
                                  @mouseover="mouseOver(true, 5)"
                                  @mouseleave="mouseOver(false, 5)"
                                >
                                  Giải tư
                                </td>
                                <td class="giai4">
                                  <div id="giai4_1_50" class="giaiSo">
                                    <template v-if="number.five.one == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.five.one == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.two
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.five.one }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai4_2_50" class="giaiSo">
                                    <template v-if="number.five.two == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.five.two == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.four
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.five.two }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai4_3_50" class="giaiSo">
                                    <template v-if="number.five.three == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template
                                        v-if="number.five.three == true"
                                      >
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.three
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.five.three }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai4_4_50" class="giaiSo">
                                    <template v-if="number.five.four == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.five.four == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.four
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.five.four }}
                                      </template>
                                    </template>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                v-bind:class="[lottery.six ? 'giaiActive' : '']"
                              >
                                <td
                                  class="giai5l"
                                  v-bind:class="[
                                    lottery.six ? 'giaiActive' : '',
                                  ]"
                                  @mouseover="mouseOver(true, 6)"
                                  @mouseleave="mouseOver(false, 6)"
                                >
                                  Giải năm
                                </td>
                                <td class="giai5">
                                  <div id="giai5_1_50" class="giaiSo">
                                    <template v-if="number.six.one == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.six.one == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.three
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.six.one }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai5_2_50" class="giaiSo">
                                    <template v-if="number.six.two == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.six.two == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.two
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.six.two }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai5_3_50" class="giaiSo">
                                    <template v-if="number.six.three == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.six.three == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.five
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.six.three }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai5_4_50" class="giaiSo">
                                    <template v-if="number.six.four == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.six.four == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.four
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.six.four }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai5_5_50" class="giaiSo">
                                    <template v-if="number.six.five == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.six.five == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.two
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.six.five }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai5_6_50" class="giaiSo">
                                    <template v-if="number.six.six == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.six.six == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.one
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.six.six }}
                                      </template>
                                    </template>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                v-bind:class="[
                                  lottery.seven ? 'giaiActive' : '',
                                ]"
                              >
                                <td
                                  class="giai6l"
                                  v-bind:class="[
                                    lottery.seven ? 'giaiActive' : '',
                                  ]"
                                  @mouseover="mouseOver(true, 7)"
                                  @mouseleave="mouseOver(false, 7)"
                                >
                                  Giải sáu
                                </td>
                                <td class="giai6">
                                  <div id="giai6_1_50" class="giaiSo">
                                    <template v-if="number.seven.one == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.seven.one == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.two
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.seven.one }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai6_2_50" class="giaiSo">
                                    <template v-if="number.seven.two == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.seven.two == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.one
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.seven.two }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai6_3_50" class="giaiSo">
                                    <template v-if="number.seven.three == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template
                                        v-if="number.seven.three == true"
                                      >
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.four +
                                            "" +
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.five
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.seven.three }}
                                      </template>
                                    </template>
                                  </div>
                                </td>
                              </tr>
                              <tr
                                v-bind:class="[
                                  lottery.eight ? 'giaiActive' : '',
                                ]"
                              >
                                <td
                                  class="giai7l"
                                  v-bind:class="[
                                    lottery.eight ? 'giaiActive' : '',
                                  ]"
                                  @mouseover="mouseOver(true, 8)"
                                  @mouseleave="mouseOver(false, 8)"
                                >
                                  Giải bảy
                                </td>
                                <td class="giai7">
                                  <div id="giai7_1_50" class="giaiSo">
                                    <template v-if="number.eight.one == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.eight.one == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.two +
                                            "" +
                                            numberCountDownPosition.three
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.eight.one }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai7_2_50" class="giaiSo">
                                    <template v-if="number.eight.two == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template v-if="number.eight.two == true">
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.one +
                                            "" +
                                            numberCountDownPosition.four
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.eight.two }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai7_3_50" class="giaiSo">
                                    <template v-if="number.eight.three == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template
                                        v-if="number.eight.three == true"
                                      >
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.three +
                                            "" +
                                            numberCountDownPosition.one
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.eight.three }}
                                      </template>
                                    </template>
                                  </div>
                                  <div id="giai7_4_50" class="giaiSo">
                                    <template v-if="number.eight.four == null">
                                      <span class="imgSpin"></span>
                                    </template>
                                    <template v-else>
                                      <template
                                        v-if="number.eight.four == true"
                                      >
                                        <label class="circle">
                                          {{
                                            numberCountDownPosition.five +
                                            "" +
                                            numberCountDownPosition.two
                                          }}
                                        </label>
                                      </template>
                                      <template v-else>
                                        {{ number.eight.four }}
                                      </template>
                                    </template>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <table class="boxBottom">
                <tbody>
                  <tr class="daysoThongkes">
                    <td class="config_Bangketqua_list">
                      <div class="config_Item" data="0">Normal</div>
                      <div class="config_Item" data="2">2 số</div>
                      <div class="config_Item" data="3">3 Số</div>
                    </td>
                    <td
                      class="daysoThongke"
                      v-bind:class="[ball.zero ? 'daysoThongkeHover' : '']"
                      @mouseover="mouseOverBall(true, 0)"
                      @mouseleave="mouseOverBall(false, 0)"
                    >
                      <div>0</div>
                    </td>
                    <td
                      class="daysoThongke"
                      v-bind:class="[ball.one ? 'daysoThongkeHover' : '']"
                      @mouseover="mouseOverBall(true, 1)"
                      @mouseleave="mouseOverBall(false, 1)"
                    >
                      <div>1</div>
                    </td>
                    <td
                      class="daysoThongke"
                      v-bind:class="[ball.two ? 'daysoThongkeHover' : '']"
                      @mouseover="mouseOverBall(true, 2)"
                      @mouseleave="mouseOverBall(false, 2)"
                    >
                      <div>2</div>
                    </td>
                    <td
                      class="daysoThongke"
                      v-bind:class="[ball.three ? 'daysoThongkeHover' : '']"
                      @mouseover="mouseOverBall(true, 3)"
                      @mouseleave="mouseOverBall(false, 3)"
                    >
                      <div>3</div>
                    </td>
                    <td
                      class="daysoThongke"
                      v-bind:class="[ball.four ? 'daysoThongkeHover' : '']"
                      @mouseover="mouseOverBall(true, 4)"
                      @mouseleave="mouseOverBall(false, 4)"
                    >
                      <div>4</div>
                    </td>
                    <td
                      class="daysoThongke"
                      v-bind:class="[ball.five ? 'daysoThongkeHover' : '']"
                      @mouseover="mouseOverBall(true, 5)"
                      @mouseleave="mouseOverBall(false, 5)"
                    >
                      <div>5</div>
                    </td>
                    <td
                      class="daysoThongke"
                      v-bind:class="[ball.six ? 'daysoThongkeHover' : '']"
                      @mouseover="mouseOverBall(true, 6)"
                      @mouseleave="mouseOverBall(false, 6)"
                    >
                      <div>6</div>
                    </td>
                    <td
                      class="daysoThongke"
                      v-bind:class="[ball.seven ? 'daysoThongkeHover' : '']"
                      @mouseover="mouseOverBall(true, 7)"
                      @mouseleave="mouseOverBall(false, 7)"
                    >
                      <div>7</div>
                    </td>
                    <td
                      class="daysoThongke"
                      v-bind:class="[ball.eight ? 'daysoThongkeHover' : '']"
                      @mouseover="mouseOverBall(true, 8)"
                      @mouseleave="mouseOverBall(false, 8)"
                    >
                      <div>8</div>
                    </td>
                    <td
                      class="daysoThongke"
                      v-bind:class="[ball.nine ? 'daysoThongkeHover' : '']"
                      @mouseover="mouseOverBall(true, 9)"
                      @mouseleave="mouseOverBall(false, 9)"
                    >
                      <div>9</div>
                    </td>
                    <td class="sodudoan">
                      <div><img src="@/assets/dudoan.png" /></div>
                    </td>
                    <td class="menuSound">
                      <a
                        href="javascript:onofsound();"
                        class="sound soundon"
                        id="soundonof"
                        >Tắt âm</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="Menutools boxdauduoimien">
                <div class="boxdauduoimien_online_mb"></div>
                <table
                  width="100%"
                  border="0"
                  cellpadding="0"
                  cellspacing="0"
                  class=""
                >
                  <tbody>
                    <tr>
                      <td class="bangSo bangSo_0" data="0" width="100%">
                        <table
                          width="100%"
                          border="0"
                          cellpadding="0"
                          cellspacing="0"
                          class=""
                        >
                          <tbody>
                            <tr>
                              <td width="49%" style="border: 0">
                                <table
                                  cellpadding="2"
                                  cellspacing="0"
                                  width="100%"
                                  style="border: 0"
                                >
                                  <tbody>
                                    <tr bgcolor="#EFEFEF">
                                      <td width="45%">Chục</td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        width="10"
                                        class="shadow somiens"
                                      >
                                        Số
                                      </td>
                                      <td width="45%">Đơn Vị</td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="tanso_hangchuc"
                                        id="dau50_0"
                                        v-bind:class="[
                                          ball.zero ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 0)"
                                        @mouseleave="mouseOverBall(false, 0)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="30"
                                          >3</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="70"
                                          >7</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="90"
                                          >9</span
                                        >
                                      </td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        class="shadow somien"
                                        v-bind:class="[
                                          ball.zero ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 0)"
                                        @mouseleave="mouseOverBall(false, 0)"
                                      >
                                        <strong>0</strong>
                                      </td>
                                      <td
                                        class="tanso_hangdonvi"
                                        id="duoi50_0"
                                        v-bind:class="[
                                          ball.zero ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 0)"
                                        @mouseleave="mouseOverBall(false, 0)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="03"
                                          >3</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="06"
                                          >6</span
                                        >,<span
                                          class="numberHightlight log"
                                          data-tanso="2"
                                          data="07"
                                          >7<span class="numberHightlight_log"
                                            >2</span
                                          ></span
                                        >,<span
                                          class="numberHightlight log"
                                          data-tanso="2"
                                          data="09"
                                          >9<span class="numberHightlight_log"
                                            >2</span
                                          ></span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="tanso_hangchuc"
                                        id="dau50_1"
                                        v-bind:class="[
                                          ball.one ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 1)"
                                        @mouseleave="mouseOverBall(false, 1)"
                                      >
                                        <span
                                          class="numberHightlight log"
                                          data-tanso="2"
                                          data="11"
                                          >1<span class="numberHightlight_log"
                                            >2</span
                                          ></span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="21"
                                          >2</span
                                        >
                                      </td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        class="shadow somien"
                                        v-bind:class="[
                                          ball.one ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 1)"
                                        @mouseleave="mouseOverBall(false, 1)"
                                      >
                                        <strong>1</strong>
                                      </td>
                                      <td
                                        class="tanso_hangdonvi"
                                        id="duoi50_1"
                                        v-bind:class="[
                                          ball.one ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 1)"
                                        @mouseleave="mouseOverBall(false, 1)"
                                      >
                                        <span
                                          class="numberHightlight log"
                                          data-tanso="2"
                                          data="11"
                                          >1<span class="numberHightlight_log"
                                            >2</span
                                          ></span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="13"
                                          >3</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="19"
                                          >9</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="tanso_hangchuc"
                                        id="dau50_2"
                                        v-bind:class="[
                                          ball.two ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 2)"
                                        @mouseleave="mouseOverBall(false, 2)"
                                      ></td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        class="shadow somien"
                                        v-bind:class="[
                                          ball.two ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 2)"
                                        @mouseleave="mouseOverBall(false, 2)"
                                      >
                                        <strong>2</strong>
                                      </td>
                                      <td
                                        class="tanso_hangdonvi"
                                        id="duoi50_2"
                                        v-bind:class="[
                                          ball.two ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 2)"
                                        @mouseleave="mouseOverBall(false, 2)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="21"
                                          >1</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="24"
                                          >4</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="27"
                                          >7</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="29"
                                          >9</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="tanso_hangchuc"
                                        id="dau50_3"
                                        v-bind:class="[
                                          ball.three ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 3)"
                                        @mouseleave="mouseOverBall(false, 3)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="03"
                                          >0</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="13"
                                          >1</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="63"
                                          >6</span
                                        >
                                      </td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        class="shadow somien"
                                        v-bind:class="[
                                          ball.three ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 3)"
                                        @mouseleave="mouseOverBall(false, 3)"
                                      >
                                        <strong>3</strong>
                                      </td>
                                      <td
                                        class="tanso_hangdonvi"
                                        id="duoi50_3"
                                        v-bind:class="[
                                          ball.three ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 3)"
                                        @mouseleave="mouseOverBall(false, 3)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="30"
                                          >0</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="39"
                                          >9</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="tanso_hangchuc"
                                        id="dau50_4"
                                        v-bind:class="[
                                          ball.four ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 4)"
                                        @mouseleave="mouseOverBall(false, 4)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="24"
                                          >2</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="64"
                                          >6</span
                                        >
                                      </td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        class="shadow somien"
                                        v-bind:class="[
                                          ball.four ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 4)"
                                        @mouseleave="mouseOverBall(false, 4)"
                                      >
                                        <strong>4</strong>
                                      </td>
                                      <td
                                        class="tanso_hangdonvi"
                                        id="duoi50_4"
                                        v-bind:class="[
                                          ball.four ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 4)"
                                        @mouseleave="mouseOverBall(false, 4)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="46"
                                          >6</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td width="49%" style="border: 0">
                                <table
                                  cellpadding="2"
                                  cellspacing="0"
                                  width="100%"
                                  style="border: 0"
                                >
                                  <tbody>
                                    <tr bgcolor="#EFEFEF">
                                      <td width="45%">Chục</td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        width="10"
                                        class="shadow somiens"
                                      >
                                        Số
                                      </td>
                                      <td width="45%">Đơn Vị</td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="tanso_hangchuc"
                                        id="dau50_5"
                                        v-bind:class="[
                                          ball.five ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 5)"
                                        @mouseleave="mouseOverBall(false, 5)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="85"
                                          >8</span
                                        >
                                      </td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        class="shadow somien"
                                        v-bind:class="[
                                          ball.five ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 5)"
                                        @mouseleave="mouseOverBall(false, 5)"
                                      >
                                        <strong>5</strong>
                                      </td>
                                      <td
                                        class="tanso_hangdonvi"
                                        id="duoi50_5"
                                        v-bind:class="[
                                          ball.five ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 5)"
                                        @mouseleave="mouseOverBall(false, 5)"
                                      ></td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="tanso_hangchuc"
                                        id="dau50_6"
                                        v-bind:class="[
                                          ball.six ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 6)"
                                        @mouseleave="mouseOverBall(false, 6)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="06"
                                          >0</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="46"
                                          >4</span
                                        >
                                      </td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        class="shadow somien"
                                        v-bind:class="[
                                          ball.six ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 6)"
                                        @mouseleave="mouseOverBall(false, 6)"
                                      >
                                        <strong>6</strong>
                                      </td>
                                      <td
                                        class="tanso_hangdonvi"
                                        id="duoi50_6"
                                        v-bind:class="[
                                          ball.six ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 6)"
                                        @mouseleave="mouseOverBall(false, 6)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="63"
                                          >3</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="64"
                                          >4</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="68"
                                          >8</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="tanso_hangchuc"
                                        id="dau50_7"
                                        v-bind:class="[
                                          ball.seven ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 7)"
                                        @mouseleave="mouseOverBall(false, 7)"
                                      >
                                        <span
                                          class="numberHightlight log"
                                          data-tanso="2"
                                          data="07"
                                          >0<span class="numberHightlight_log"
                                            >2</span
                                          ></span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="27"
                                          >2</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="87"
                                          >8</span
                                        >
                                      </td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        class="shadow somien"
                                        v-bind:class="[
                                          ball.seven ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 7)"
                                        @mouseleave="mouseOverBall(false, 7)"
                                      >
                                        <strong>7</strong>
                                      </td>
                                      <td
                                        class="tanso_hangdonvi"
                                        id="duoi50_7"
                                        v-bind:class="[
                                          ball.seven ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 7)"
                                        @mouseleave="mouseOverBall(false, 7)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="70"
                                          >0</span
                                        >,<span
                                          class="numberHightlight log"
                                          data-tanso="2"
                                          data="78"
                                          >8<span class="numberHightlight_log"
                                            >2</span
                                          ></span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="tanso_hangchuc"
                                        id="dau50_8"
                                        v-bind:class="[
                                          ball.eight ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 8)"
                                        @mouseleave="mouseOverBall(false, 8)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="68"
                                          >6</span
                                        >,<span
                                          class="numberHightlight log"
                                          data-tanso="2"
                                          data="78"
                                          >7<span class="numberHightlight_log"
                                            >2</span
                                          ></span
                                        >
                                      </td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        class="shadow somien"
                                        v-bind:class="[
                                          ball.eight ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 8)"
                                        @mouseleave="mouseOverBall(false, 8)"
                                      >
                                        <strong>8</strong>
                                      </td>
                                      <td
                                        class="tanso_hangdonvi"
                                        id="duoi50_8"
                                        v-bind:class="[
                                          ball.eight ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 8)"
                                        @mouseleave="mouseOverBall(false, 8)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="85"
                                          >5</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="87"
                                          >7</span
                                        >
                                      </td>
                                    </tr>
                                    <tr>
                                      <td
                                        class="tanso_hangchuc"
                                        id="dau50_9"
                                        v-bind:class="[
                                          ball.nine ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 9)"
                                        @mouseleave="mouseOverBall(false, 9)"
                                      >
                                        <span
                                          class="numberHightlight log"
                                          data-tanso="2"
                                          data="09"
                                          >0<span class="numberHightlight_log"
                                            >2</span
                                          ></span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="19"
                                          >1</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="29"
                                          >2</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="39"
                                          >3</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="99"
                                          >9</span
                                        >
                                      </td>
                                      <td
                                        bgcolor="#EFEFEF"
                                        class="shadow somien"
                                        v-bind:class="[
                                          ball.nine ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 9)"
                                        @mouseleave="mouseOverBall(false, 9)"
                                      >
                                        <strong>9</strong>
                                      </td>
                                      <td
                                        class="tanso_hangdonvi"
                                        id="duoi50_9"
                                        v-bind:class="[
                                          ball.nine ? 'hangActive' : '',
                                        ]"
                                        @mouseover="mouseOverBall(true, 9)"
                                        @mouseleave="mouseOverBall(false, 9)"
                                      >
                                        <span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="90"
                                          >0</span
                                        >,<span
                                          class="numberHightlight"
                                          data-tanso="1"
                                          data="99"
                                          >9</span
                                        >
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                  <tbody></tbody>
                </table>
              </div>
              <div class="bottom">
                <div class="bkl">
                  <div class="bkr">
                    <div class="bkm"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bangkqxs_link">
              <table border="0" cellpadding="0" cellspacing="4">
                <tbody>
                  <tr>
                    <td nowrap="nowrap" valign="middle">
                      <a href="/doi-so-trung/mien-bac.html" target="doisotrung">
                        <img
                          align="absMiddle"
                          alt=""
                          border="0"
                          height="20"
                          hspace="5"
                          src="@/assets/doisotrung.gif"
                          width="20"
                        />Đổi số trúng
                      </a>
                    </td>
                    <td nowrap="nowrap" valign="middle">
                      <a href="/ve-so/mien-bac/06-02-2021.html" target="_blank">
                        <img
                          align="absMiddle"
                          alt=""
                          border="0"
                          hspace="5"
                          src="@/assets/veso.gif"
                        />Hình Vé Số
                      </a>
                    </td>
                    <td nowrap="nowrap" valign="middle">
                      <a href="javascript:print_vedo_tructiep(2)">
                        <img
                          align="absMiddle"
                          alt=""
                          border="0"
                          hspace="5"
                          src="@/assets/print.gif"
                        />In vé dò
                      </a>
                    </td>
                    <td nowrap="nowrap" valign="middle">
                      <a href="http://www.minhngoc.net" target="_blank">
                        <img
                          align="absMiddle"
                          alt=""
                          border="0"
                          hspace="5"
                          src="@/assets/icon.gif"
                        />www.minhngoc.net
                      </a>
                    </td>
                    <td nowrap="nowrap" valign="middle"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="clear"></div>
          </div>
        </center>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["time", "textTitle", "date", "numberBall", "headText", "number"],
  data() {
    return {
      lotteryHead: false,
      lottery: {
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        seven: false,
        eight: false,
      },
      ball: {
        zero: false,
        one: false,
        two: false,
        three: false,
        four: false,
        five: false,
        seven: false,
        eight: false,
        nine: false,
      },
      numberCountDownPosition: {
        one: 0,
        two: 2,
        three: 4,
        four: 1,
        five: 3,
      },
      stringRandom: {
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: "",
      },
    };
  },
  async mounted() {
    window.setInterval(() => {
      this.numberCountDown();
      this.randomStringText();
    }, 100);
  },
  methods: {
    mouseOver: function (status, lottery) {
      if (lottery == 0) {
        this.lotteryHead = status;
      } else if (lottery == 1) {
        this.lottery.one = status;
      } else if (lottery == 2) {
        this.lottery.two = status;
      } else if (lottery == 3) {
        this.lottery.three = status;
      } else if (lottery == 4) {
        this.lottery.four = status;
      } else if (lottery == 5) {
        this.lottery.five = status;
      } else if (lottery == 6) {
        this.lottery.six = status;
      } else if (lottery == 7) {
        this.lottery.seven = status;
      } else if (lottery == 8) {
        this.lottery.eight = status;
      }
    },
    mouseOverBall: function (status, ball) {
      if (ball == 0) {
        this.ball.zero = status;
      } else if (ball == 1) {
        this.ball.one = status;
      } else if (ball == 2) {
        this.ball.two = status;
      } else if (ball == 3) {
        this.ball.three = status;
      } else if (ball == 4) {
        this.ball.four = status;
      } else if (ball == 5) {
        this.ball.five = status;
      } else if (ball == 6) {
        this.ball.six = status;
      } else if (ball == 7) {
        this.ball.seven = status;
      } else if (ball == 8) {
        this.ball.eight = status;
      } else if (ball == 9) {
        this.ball.nine = status;
      }
    },
    numberCountDown() {
      if (this.numberCountDownPosition.one >= 9) {
        this.numberCountDownPosition.one = 0;
      } else {
        this.numberCountDownPosition.one += 1;
      }
      if (this.numberCountDownPosition.two >= 9) {
        this.numberCountDownPosition.two = 0;
      } else {
        this.numberCountDownPosition.two += 1;
      }
      if (this.numberCountDownPosition.three >= 9) {
        this.numberCountDownPosition.three = 0;
      } else {
        this.numberCountDownPosition.three += 1;
      }
      if (this.numberCountDownPosition.four >= 9) {
        this.numberCountDownPosition.four = 0;
      } else {
        this.numberCountDownPosition.four += 1;
      }
      if (this.numberCountDownPosition.five >= 9) {
        this.numberCountDownPosition.five = 0;
      } else {
        this.numberCountDownPosition.five += 1;
      }
    },
    randomStringText() {
      this.stringRandom.one = this.randomString(2);
      this.stringRandom.two = this.randomString(2);
      this.stringRandom.three = this.randomString(2);
      this.stringRandom.four = this.randomString(2);
      this.stringRandom.five = this.randomString(2);
      this.stringRandom.six = this.randomString(2);
    },
    randomString(length) {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },
};
</script>

<style>
.imgSpin {
  background: url("../assets/loader-red.gif") no-repeat left 0;
  display: inline-block;
  width: 20px;
  height: 20px;
}
.circle {
  border: 2px solid;
  border-radius: 50%;
  display: inline-block;
  border-color: rgb(255, 248, 153);
  color: rgb(255, 123, 0);
  background-color: rgb(253, 249, 197);
}
</style>
