import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import Style from './assets/style.css'
import router from './router'

Vue.config.productionTip = false
// Global Variable
Vue.prototype.baseUrl = 'https://hanoi-super-vip-manage-hpqd3pi5za-as.a.run.app/api/'
Vue.prototype.lotteryName = 'ฮานอย ซุปเปอร์ VIP'
Vue.prototype.startHour = 17
Vue.prototype.endHour = 18

new Vue({
  Style,
  router,
  render: h => h(App)
}).$mount('#app')
