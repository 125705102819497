<template>
<div class="dnw-nav-l">
    <div class="dnw-nav-r">
        <div style="clear:both"></div>
        <div id="main-menu">
            <div id="smoothmenu" class="ddsmoothmenu">
                <ul>
                    <li class="current" style="z-index: 100;" @mouseleave="mouseOver(false, 1)">
                        <a href="/minh-ngoc.html" v-bind:class="[ menuOne ? 'selected' : '' ]" @mouseover="mouseOver(true, 1)"><span>Minh Ngọc ™ </span></a>
                        <ul class="submenu-border" style="top: 30px; visibility: visible; left: 0px; width: 172px;" v-bind:style="[ menuOne ? { display: 'block' } : { display: 'none' }  ]">
                            <div class="top-menu-popup">
                                <li>
                                    <a href="/dai-ly-ve-so.html"><span>Đại Lý Vé Số</span></a>
                                </li>
                                <li>
                                    <a href="/page/gioi-thieu.html"><span>Giới Thiệu Hệ Thống</span></a>
                                </li>
                                <li>
                                    <a href="/lienhe.html"><span>Liên Hệ Góp Ý</span></a>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <li style="z-index: 99;" @mouseleave="mouseOver(false, 2)">
                        <a href="/xo-so-truc-tiep.html" class="" v-bind:class="[ menuTwo ? 'selected' : '' ]" @mouseover="mouseOver(true, 2)"><span>Trực Tiếp</span></a>
                        <ul class="submenu-border" style="top: 30px; visibility: visible;" v-bind:style="[ menuTwo ? { display: 'block' } : { display: 'none' }  ]">
                            <div class="top-menu-popup">
                                <li>
                                    <a href="/xo-so-truc-tiep/mien-nam.html"><span>Trực Tiếp Xổ Số Miền Nam</span></a>
                                </li>
                                <li>
                                    <a href="/xo-so-truc-tiep/mien-bac.html"><span>Trực Tiếp Xổ Số Miền Bắc</span></a>
                                </li>
                                <li>
                                    <a href="/xo-so-truc-tiep/mien-trung.html"><span>Trực Tiếp Xổ Số Miền Trung</span></a>
                                </li>
                                <li>
                                    <a href="/page/livescore.html"><span>Trực Tiếp Kết Quả Bóng Đá</span></a>
                                </li>
                                <li>
                                    <a href="/thong-tin/lich-quay-so-mo-thuong.html"><span>Lịch Quay Số Mở Thưởng</span></a>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <li style="z-index: 98;" @mouseleave="mouseOver(false, 3)">
                        <a href="#" class="" v-bind:class="[ menuThree ? 'selected' : '' ]" @mouseover="mouseOver(true, 3)"><span>Sổ Kết Quả</span></a>
                        <ul class="submenu-border" style="top: 30px; visibility: visible; left: 0px; width: 172px;" v-bind:style="[ menuThree ? { display: 'block' } : { display: 'none' }  ]">
                            <div class="top-menu-popup">
                                <li style="z-index: 97;">
                                    <a href="/ket-qua-xo-so/mien-nam.html" class="parent"><span>KQXS Miền Nam</span></a>
                                    <ul class="submenu-border" style="top: 0px; visibility: visible; left: 170px; width: 172px; display: none;">
                                        <div class="top-menu-popup">
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam.html"><span>Xem tất Cả</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/thu-hai.html"><span>Thứ Hai</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/thu-ba.html"><span>Thứ Ba</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/thu-tu.html"><span>Thứ Tư</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/thu-nam.html"><span>Thứ Năm</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/thu-sau.html"><span>Thứ sáu</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/thu-bay.html"><span>Thứ bảy</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/chu-nhat.html"><span>Chủ Nhật</span></a>
                                            </li>
                                            <li>
                                                <a href="/thong-tin/co-cau-giai-thuong-mien-nam.html"><span>Cơ Cấu Giải Thưởng</span></a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li style="z-index: 96;">
                                    <a href="/ket-qua-xo-so/mien-bac.html" class="parent"><span>KQXS Miền Bắc</span></a>
                                    <ul class="submenu-border" style="display: none; top: 0px; visibility: visible;">
                                    <div class="top-menu-popup">
                                        <li>
                                            <a href="/ket-qua-xo-so/mien-bac.html"><span>Xem tất Cả</span></a>
                                        </li>
                                        <li>
                                            <a href="/ket-qua-xo-so/mien-bac/thu-hai.html"><span>Thứ Hai</span></a>
                                        </li>
                                        <li>
                                            <a href="/ket-qua-xo-so/mien-bac/thu-ba.html"><span>Thứ Ba</span></a>
                                        </li>
                                        <li>
                                            <a href="/ket-qua-xo-so/mien-bac/thu-tu.html"><span>Thứ Tư</span></a>
                                        </li>
                                        <li>
                                            <a href="/ket-qua-xo-so/mien-bac/thu-nam.html"><span>Thứ Năm</span></a>
                                        </li>
                                        <li>
                                            <a href="/ket-qua-xo-so/mien-bac/thu-sau.html"><span>Thứ sáu</span></a>
                                        </li>
                                        <li>
                                            <a href="/ket-qua-xo-so/mien-bac/thu-bay.html"><span>Thứ bảy</span></a>
                                        </li>
                                        <li>
                                            <a href="/ket-qua-xo-so/mien-bac/chu-nhat.html"><span>Chủ Nhật</span></a>
                                        </li>
                                        <li>
                                            <a href="/thong-tin/co-cau-giai-thuong-mien-bac.html"><span>Cơ Cấu Giải Thưởng</span></a>
                                        </li>
                                    </div>
                                    </ul>
                                </li>
                                <li style="z-index: 95;">
                                    <a href="/ket-qua-xo-so/mien-trung.html" class="parent"><span>KQXS Miền Trung</span></a>
                                    <ul class="submenu-border" style="display: none; top: 0px; visibility: visible;">
                                        <div class="top-menu-popup">
                                            <li>
                                            <a href="/ket-qua-xo-so/mien-trung.html"><span>Xem tất Cả</span></a>
                                            </li>
                                            <li>
                                            <a href="/ket-qua-xo-so/mien-trung/thu-hai.html"><span>Thứ Hai</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/thu-ba.html"><span>Thứ Ba</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/thu-tu.html"><span>Thứ Tư</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/thu-nam.html"><span>Thứ Năm</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/thu-sau.html"><span>Thứ sáu</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/thu-bay.html"><span>Thứ bảy</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/chu-nhat.html"><span>Chủ Nhật</span></a>
                                            </li>
                                            <li>
                                                <a href="/thong-tin/co-cau-giai-thuong-mien-trung.html"><span>Cơ Cấu Giải Thưởng</span></a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li style="z-index: 94;">
                                    <a href="/kqxs.html" class="parent"><span>KQXS Theo Tỉnh/TP</span></a>
                                    <ul class="submenu-border" id="menukqxstinh" style="display: none; top: 0px; visibility: visible;">
                                        <div class="top-menu-popup">
                                            <li class="root">
                                                <a href="/kqxs/mien-nam.html" title="Kết quả xổ số Miền Nam - Xem KQXS các tỉnh Miền Nam theo ngày"><span><strong>Kết quả xổ số Miền Nam</strong></span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/an-giang.html" title="Kết quả xổ số An Giang - Xổ số Miền Nam"><span>Kết quả xổ số An Giang</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/bac-lieu.html" title="Kết quả xổ số Bạc Liêu - Xổ số Miền Nam"><span>Kết quả xổ số Bạc Liêu</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/ben-tre.html" title="Kết quả xổ số Bến Tre - Xổ số Miền Nam"><span>Kết quả xổ số Bến Tre</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/binh-duong.html" title="Kết quả xổ số Bình Dương - Xổ số Miền Nam"><span>Kết quả xổ số Bình Dương</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/binh-phuoc.html" title="Kết quả xổ số Bình Phước - Xổ số Miền Nam"><span>Kết quả xổ số Bình Phước</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/binh-thuan.html" title="Kết quả xổ số Bình Thuận - Xổ số Miền Nam"><span>Kết quả xổ số Bình Thuận</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/ca-mau.html" title="Kết quả xổ số Cà Mau - Xổ số Miền Nam"><span>Kết quả xổ số Cà Mau</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/can-tho.html" title="Kết quả xổ số Cần Thơ - Xổ số Miền Nam"><span>Kết quả xổ số Cần Thơ</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/da-lat.html" title="Kết quả xổ số Đà Lạt - Xổ số Miền Nam"><span>Kết quả xổ số Đà Lạt</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/dong-nai.html" title="Kết quả xổ số Đồng Nai - Xổ số Miền Nam"><span>Kết quả xổ số Đồng Nai</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/dong-thap.html" title="Kết quả xổ số Đồng Tháp - Xổ số Miền Nam"><span>Kết quả xổ số Đồng Tháp</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/hau-giang.html" title="Kết quả xổ số Hậu Giang - Xổ số Miền Nam"><span>Kết quả xổ số Hậu Giang</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/kien-giang.html" title="Kết quả xổ số Kiên Giang - Xổ số Miền Nam"><span>Kết quả xổ số Kiên Giang</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/long-an.html" title="Kết quả xổ số Long An - Xổ số Miền Nam"><span>Kết quả xổ số Long An</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/soc-trang.html" title="Kết quả xổ số Sóc Trăng - Xổ số Miền Nam"><span>Kết quả xổ số Sóc Trăng</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/tay-ninh.html" title="Kết quả xổ số Tây Ninh - Xổ số Miền Nam"><span>Kết quả xổ số Tây Ninh</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/tien-giang.html" title="Kết quả xổ số Tiền Giang - Xổ số Miền Nam"><span>Kết quả xổ số Tiền Giang</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/tp-hcm.html" title="Kết quả xổ số TP. HCM - Xổ số Miền Nam"><span>Kết quả xổ số TP. HCM</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/tra-vinh.html" title="Kết quả xổ số Trà Vinh - Xổ số Miền Nam"><span>Kết quả xổ số Trà Vinh</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/vinh-long.html" title="Kết quả xổ số Vĩnh Long - Xổ số Miền Nam"><span>Kết quả xổ số Vĩnh Long</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-nam/vung-tau.html" title="Kết quả xổ số Vũng Tàu - Xổ số Miền Nam"><span>Kết quả xổ số Vũng Tàu</span></a>
                                            </li>
                                            <li class="root">
                                                <a href="/kqxs/mien-bac.html" title="Kết quả xổ số Miền Bắc - Xem KQXS các tỉnh Miền Bắc theo ngày"><span><strong>Kết quả xổ số Miền Bắc</strong></span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-bac/bac-ninh.html" title="Kết quả xổ số Bắc Ninh - Xổ số Miền Bắc"><span>Kết quả xổ số Bắc Ninh</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-bac/ha-noi.html" title="Kết quả xổ số Hà Nội - Xổ số Miền Bắc"><span>Kết quả xổ số Hà Nội</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-bac/hai-phong.html" title="Kết quả xổ số Hải Phòng - Xổ số Miền Bắc"><span>Kết quả xổ số Hải Phòng</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-bac/nam-dinh.html" title="Kết quả xổ số Nam Định - Xổ số Miền Bắc"><span>Kết quả xổ số Nam Định</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-bac/quang-ninh.html" title="Kết quả xổ số Quảng Ninh - Xổ số Miền Bắc"><span>Kết quả xổ số Quảng Ninh</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-bac/thai-binh.html" title="Kết quả xổ số Thái Bình - Xổ số Miền Bắc"><span>Kết quả xổ số Thái Bình</span></a>
                                            </li>
                                            <li class="root">
                                                <a href="/kqxs/mien-trung.html" title="Kết quả xổ số Miền Trung - Xem KQXS các tỉnh Miền Trung theo ngày"><span><strong>Kết quả xổ số Miền Trung</strong></span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/binh-dinh.html" title="Kết quả xổ số Bình Định - Xổ số Miền Trung"><span>Kết quả xổ số Bình Định</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/da-nang.html" title="Kết quả xổ số Đà Nẵng - Xổ số Miền Trung"><span>Kết quả xổ số Đà Nẵng</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/dak-lak.html" title="Kết quả xổ số Đắk Lắk - Xổ số Miền Trung"><span>Kết quả xổ số Đắk Lắk</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/dak-nong.html" title="Kết quả xổ số Đắk Nông - Xổ số Miền Trung"><span>Kết quả xổ số Đắk Nông</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/gia-lai.html" title="Kết quả xổ số Gia Lai - Xổ số Miền Trung"><span>Kết quả xổ số Gia Lai</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/khanh-hoa.html" title="Kết quả xổ số Khánh Hòa - Xổ số Miền Trung"><span>Kết quả xổ số Khánh Hòa</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/kon-tum.html" title="Kết quả xổ số Kon Tum - Xổ số Miền Trung"><span>Kết quả xổ số Kon Tum</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/ninh-thuan.html" title="Kết quả xổ số Ninh Thuận - Xổ số Miền Trung"><span>Kết quả xổ số Ninh Thuận</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/phu-yen.html" title="Kết quả xổ số Phú Yên - Xổ số Miền Trung"><span>Kết quả xổ số Phú Yên</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/quang-binh.html" title="Kết quả xổ số Quảng Bình - Xổ số Miền Trung"><span>Kết quả xổ số Quảng Bình</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/quang-nam.html" title="Kết quả xổ số Quảng Nam - Xổ số Miền Trung"><span>Kết quả xổ số Quảng Nam</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/quang-ngai.html" title="Kết quả xổ số Quảng Ngãi - Xổ số Miền Trung"><span>Kết quả xổ số Quảng Ngãi</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/quang-tri.html" title="Kết quả xổ số Quảng Trị - Xổ số Miền Trung"><span>Kết quả xổ số Quảng Trị</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/mien-trung/thua-thien-hue.html" title="Kết quả xổ số Thừa T. Huế - Xổ số Miền Trung"><span>Kết quả xổ số Thừa T. Huế</span></a>
                                            </li>
                                            <li class="root">
                                                <a href="/kqxs/dien-toan-vietlott.html" title="Kết quả xổ số Vietlott - Xem KQXS các tỉnh Vietlott theo ngày"><span><strong>Kết quả xổ số Vietlott</strong></span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/dien-toan-vietlott/max-4d.html" title="Kết quả xổ số Max 4D - Xổ số Vietlott"><span>Kết quả xổ số Max 4D</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/dien-toan-vietlott/mega-6x45.html" title="Kết quả xổ số Mega 6/45 - Xổ số Vietlott"><span>Kết quả xổ số Mega 6/45</span></a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li style="z-index: 93;">
                                    <a href="/xo-so-dien-toan.html" class="parent"><span>KQXS Điện Toán</span></a>
                                    <ul class="submenu-border" style="display: none; top: 0px; visibility: visible;">
                                        <div class="top-menu-popup">
                                            <li>
                                                <a href="/xo-so-dien-toan.html" title="Xổ số Điện Toán "><span>Xem tất cả</span></a>
                                            </li>
                                            <li>
                                                <a href="/xo-so-dien-toan/1*2*3.html" title="Click xem tất cả 1*2*3"><span>Xổ Số 1*2*3</span></a>
                                            </li>
                                            <li>
                                                <a href="/xo-so-dien-toan/6x36.html" title="Click xem tất cả 6X36"><span>Xổ Số 6X36</span></a>
                                            </li>
                                            <li>
                                                <a href="/xo-so-dien-toan/than-tai-4.html" title="Click xem tất cả Thần Tài 4"><span>Xổ Số Thần Tài 4</span></a>
                                            </li>
                                            <li>
                                                <a href="/xo-so-dien-toan/mega-millions.html" title="Click xem tất cả Mega Millions (USA)"><span>Xổ Số Mega Millions (USA)</span></a>
                                            </li>
                                            <li>
                                                <a href="/xo-so-dien-toan/power-6x55.html" title="Click xem tất cả Power 6/55"><span>Xổ Số Power 6/55</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/dien-toan-vietlott/mega-6x45.html" title="Click xem tất cả Mega 6/45"><span>Xổ Số Mega 6/45</span></a>
                                            </li>
                                            <li>
                                                <a href="/ket-qua-xo-so/dien-toan-vietlott/max-4d.html" title="Click xem tất cả Max 4D"><span>Xổ Số Max 4D</span></a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <li style="z-index: 92;" @mouseleave="mouseOver(false, 4)">
                        <a href="/so-dau-duoi/mien-nam.html" class="" v-bind:class="[ menuFour ? 'selected' : '' ]" @mouseover="mouseOver(true, 4)"><span>Sớ Đầu Đuôi</span></a>
                        <ul class="submenu-border" style="top: 30px; visibility: visible; left: 0px; width: 172px;" v-bind:style="[ menuFour ? { display: 'block' } : { display: 'none' }  ]">
                            <div class="top-menu-popup">
                                <li style="z-index: 91;">
                                    <a href="/so-dau-duoi/mien-nam.html" class="parent"><span>Sớ Miền Nam</span></a>
                                    <ul class="submenu-border" style="display: none; top: 0px; visibility: visible;">
                                        <div class="top-menu-popup">
                                            <li>
                                                <a href="/so-dau-duoi/mien-nam.html"><span>Xem tất cả</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-nam/thu-hai.html"><span>Thứ Hai</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-nam/thu-ba.html"><span>Thứ Ba</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-nam/thu-tu.html"><span>Thứ Tư</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-nam/thu-nam.html"><span>Thứ Năm</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-nam/thu-sau.html"><span>Thứ sáu</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-nam/thu-bay.html"><span>Thứ bảy</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-nam/chu-nhat.html"><span>Chủ Nhật</span></a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li style="z-index: 90;">
                                    <a href="/so-dau-duoi/mien-bac.html" class="parent"><span>Sớ Miền Bắc</span></a>
                                    <ul class="submenu-border" style="display: none; top: 0px; visibility: visible;">
                                        <div class="top-menu-popup">
                                            <li>
                                                <a href="/so-dau-duoi/mien-bac.html"><span>Xem tất cả</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-bac/thu-hai.html"><span>Thứ Hai</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-bac/thu-ba.html"><span>Thứ Ba</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-bac/thu-tu.html"><span>Thứ Tư</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-bac/thu-nam.html"><span>Thứ Năm</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-bac/thu-sau.html"><span>Thứ sáu</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-bac/thu-bay.html"><span>Thứ bảy</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-bac/chu-nhat.html"><span>Chủ Nhật</span></a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li style="z-index: 89;">
                                    <a href="/so-dau-duoi/mien-trung.html" class="parent"><span>Sớ Miền Trung</span></a>
                                    <ul class="submenu-border" style="display: none; top: 0px; visibility: visible;">
                                        <div class="top-menu-popup">
                                            <li>
                                                <a href="/so-dau-duoi/mien-trung.html"><span>Xem tất cả</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-trung/thu-hai.html"><span>Thứ Hai</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-trung/thu-ba.html"><span>Thứ Ba</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-trung/thu-tu.html"><span>Thứ Tư</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-trung/thu-nam.html"><span>Thứ Năm</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-trung/thu-sau.html"><span>Thứ sáu</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-trung/thu-bay.html"><span>Thứ bảy</span></a>
                                            </li>
                                            <li>
                                                <a href="/so-dau-duoi/mien-trung/chu-nhat.html"><span>Chủ Nhật</span></a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <li style="z-index: 88;" @mouseleave="mouseOver(false, 5)">
                        <a href="#" class="" v-bind:class="[ menuFive ? 'selected' : '' ]" @mouseover="mouseOver(true, 5)"><span>Thống Kê</span></a>
                        <ul class="submenu-border" style="top: 30px; visibility: visible;" v-bind:style="[ menuFive ? { display: 'block' } : { display: 'none' }  ]">
                            <div class="top-menu-popup">
                                <li>
                                    <a href="#"><span>Thống Kê Theo Tỉnh</span></a>
                                </li>
                                <li class="child">
                                    <a href="/thong-ke-xo-so/lo-to-tinh.html"><span>Thống Kê Lô</span></a>
                                </li>
                                <li class="child">
                                    <a href="/thong-ke-xo-so/gan-cuc-dai-tinh.html"><span>Kiểm Tra Gan Cực Đại</span></a>
                                </li>
                                <li class="child">
                                    <a href="/thong-ke-xo-so/tan-suat-tinh.html"><span>Thống Kê Tần Suất</span></a>
                                </li>
                                <li class="child">
                                    <a href="/thong-ke-xo-so/tan-suat-chi-tiet-tinh.html" target="_blank"><span>Thống Kê Tần Suất Chi Tiết</span></a>
                                </li>
                                <li>
                                    <a href="#"><span>Thống Kê Theo Miền</span></a>
                                </li>
                                <li class="child">
                                    <a href="/thong-ke-xo-so/lo-to-mien.html"><span>Thống Kê Lô</span></a>
                                </li>
                                <li class="child">
                                    <a href="/thong-ke-xo-so/gan-cuc-dai-mien.html"><span>Kiểm Tra Gan Cực Đại</span></a>
                                </li>
                                <li class="child">
                                    <a href="/thong-ke-xo-so/tan-suat-mien.html"><span>Thống Kê Tần Suất</span></a>
                                </li>
                                <li class="child">
                                    <a href="/thong-ke-xo-so/tan-suat-chi-tiet-mien.html" target="_blank"><span>Thống Kê Tần Suất Chi Tiết</span></a>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <li style="z-index: 87;" @mouseleave="mouseOver(false, 6)">
                        <a href="/ve-so.html" class="" v-bind:class="[ menuSix ? 'selected' : '' ]" @mouseover="mouseOver(true, 6)"><span>Vé Số</span></a>
                        <ul class="submenu-border" style="top: 30px; visibility: visible; left: 0px; width: 172px;" v-bind:style="[ menuSix ? { display: 'block' } : { display: 'none' }  ]">
                            <div class="top-menu-popup">
                                <li style="z-index: 86;">
                                    <a class="parent" href="/ve-so/mien-nam.html" title="Xem Vé Số Miền Nam"><span>Vé Số Miền Nam</span></a>
                                    <ul class="submenu-border" style="top: 0px; visibility: visible; left: 170px; width: 172px; display: none;">
                                        <div class="top-menu-popup">
                                            <li>
                                                <a href="/ve-so/mien-nam.html" title="Vé Số"><span>Xem tất cả</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/an-giang.html" title="Xem Vé Số An Giang"><span>Vé Số An Giang</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/bac-lieu.html" title="Xem Vé Số Bạc Liêu"><span>Vé Số Bạc Liêu</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/ben-tre.html" title="Xem Vé Số Bến Tre"><span>Vé Số Bến Tre</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/binh-duong.html" title="Xem Vé Số Bình Dương"><span>Vé Số Bình Dương</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/binh-phuoc.html" title="Xem Vé Số Bình Phước"><span>Vé Số Bình Phước</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/binh-thuan.html" title="Xem Vé Số Bình Thuận"><span>Vé Số Bình Thuận</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/ca-mau.html" title="Xem Vé Số Cà Mau"><span>Vé Số Cà Mau</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/can-tho.html" title="Xem Vé Số Cần Thơ"><span>Vé Số Cần Thơ</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/da-lat.html" title="Xem Vé Số Đà Lạt"><span>Vé Số Đà Lạt</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/dong-nai.html" title="Xem Vé Số Đồng Nai"><span>Vé Số Đồng Nai</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/dong-thap.html" title="Xem Vé Số Đồng Tháp"><span>Vé Số Đồng Tháp</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/hau-giang.html" title="Xem Vé Số Hậu Giang"><span>Vé Số Hậu Giang</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/kien-giang.html" title="Xem Vé Số Kiên Giang"><span>Vé Số Kiên Giang</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/long-an.html" title="Xem Vé Số Long An"><span>Vé Số Long An</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/soc-trang.html" title="Xem Vé Số Sóc Trăng"><span>Vé Số Sóc Trăng</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/tay-ninh.html" title="Xem Vé Số Tây Ninh"><span>Vé Số Tây Ninh</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/tien-giang.html" title="Xem Vé Số Tiền Giang"><span>Vé Số Tiền Giang</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/tp-hcm.html" title="Xem Vé Số TP. HCM"><span>Vé Số TP. HCM</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/tra-vinh.html" title="Xem Vé Số Trà Vinh"><span>Vé Số Trà Vinh</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/vinh-long.html" title="Xem Vé Số Vĩnh Long"><span>Vé Số Vĩnh Long</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-nam/vung-tau.html" title="Xem Vé Số Vũng Tàu"><span>Vé Số Vũng Tàu</span></a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li style="z-index: 85;">
                                    <a class="parent" href="/ve-so/mien-bac.html" title="Xem Vé Số Miền Bắc"><span>Vé Số Miền Bắc</span></a>
                                    <ul class="submenu-border" style="display: none; top: 0px; visibility: visible;">
                                        <div class="top-menu-popup">
                                            <li>
                                                <a href="/ve-so/mien-bac.html" title="Vé Số"><span>Xem tất cả</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-bac/bac-ninh.html" title="Xem Vé Số Bắc Ninh"><span>Vé Số Bắc Ninh</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-bac/ha-noi.html" title="Xem Vé Số Hà Nội"><span>Vé Số Hà Nội</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-bac/hai-phong.html" title="Xem Vé Số Hải Phòng"><span>Vé Số Hải Phòng</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-bac/nam-dinh.html" title="Xem Vé Số Nam Định"><span>Vé Số Nam Định</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-bac/quang-ninh.html" title="Xem Vé Số Quảng Ninh"><span>Vé Số Quảng Ninh</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-bac/thai-binh.html" title="Xem Vé Số Thái Bình"><span>Vé Số Thái Bình</span></a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                                <li style="z-index: 84;">
                                    <a class="parent" href="/ve-so/mien-trung.html" title="Xem Vé Số Miền Trung"><span>Vé Số Miền Trung</span></a>
                                    <ul class="submenu-border" style="display: none; top: 0px; visibility: visible;">
                                        <div class="top-menu-popup">
                                            <li>
                                                <a href="/ve-so/mien-trung.html" title="Vé Số"><span>Xem tất cả</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/binh-dinh.html" title="Xem Vé Số Bình Định"><span>Vé Số Bình Định</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/da-nang.html" title="Xem Vé Số Đà Nẵng"><span>Vé Số Đà Nẵng</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/dak-lak.html" title="Xem Vé Số Đắk Lắk"><span>Vé Số Đắk Lắk</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/dak-nong.html" title="Xem Vé Số Đắk Nông"><span>Vé Số Đắk Nông</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/gia-lai.html" title="Xem Vé Số Gia Lai"><span>Vé Số Gia Lai</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/khanh-hoa.html" title="Xem Vé Số Khánh Hòa"><span>Vé Số Khánh Hòa</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/kon-tum.html" title="Xem Vé Số Kon Tum"><span>Vé Số Kon Tum</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/ninh-thuan.html" title="Xem Vé Số Ninh Thuận"><span>Vé Số Ninh Thuận</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/phu-yen.html" title="Xem Vé Số Phú Yên"><span>Vé Số Phú Yên</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/quang-binh.html" title="Xem Vé Số Quảng Bình"><span>Vé Số Quảng Bình</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/quang-nam.html" title="Xem Vé Số Quảng Nam"><span>Vé Số Quảng Nam</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/quang-ngai.html" title="Xem Vé Số Quảng Ngãi"><span>Vé Số Quảng Ngãi</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/quang-tri.html" title="Xem Vé Số Quảng Trị"><span>Vé Số Quảng Trị</span></a>
                                            </li>
                                            <li>
                                                <a href="/ve-so/mien-trung/thua-thien-hue.html" title="Xem Vé Số Thừa T. Huế"><span>Vé Số Thừa T. Huế</span></a>
                                            </li>
                                        </div>
                                    </ul>
                                </li>
                            </div>
                        </ul>
                    </li>
                    <li>
                        <a href="/in-ve-do.html"><span>In Vé Dò</span></a>
                    </li>
                    <li>
                        <a href="/tin-tuc.html"><span>Thông Tin</span></a>
                    </li>
                    <li>
                        <a href="/doi-so-trung.html"><span>ĐỔI SỐ TRÚNG</span></a>
                    </li>
                    <li style="float: right; z-index: 83;" @mouseleave="mouseOver(false, 10)">
                        <a href="/help.html" class="" v-bind:class="[ menuTen ? 'selected' : '' ]" @mouseover="mouseOver(true, 10)"><span>Help ?</span></a>
                        <ul class="submenu-border" style="top: 30px; visibility: visible; left: 0px; width: 252px;" v-bind:style="[ menuTen ? { display: 'block' } : { display: 'none' }  ]">
                            <div class="top-menu-popup">
                                <li>
                                    <a href="/help/41-co-gi-moi-trong-phien-ban-nay.html"><span>Có gì mới trong phiên bản này</span></a>
                                </li>
                                <li>
                                    <a href="/help/42-lay-ket-qua-xo-so-ve-websites-cua-ban.html"><span>Lấy kết quả xổ số về websites của bạn</span></a>
                                </li>
                                <li>
                                    <a href="/help/54-in-ve-do-tren-chrome.html"><span>IN VÉ DÒ TRÊN CHROME</span></a>
                                </li>
                                <li>
                                    <a href="/help/56-in-ve-do-tren-firefox.html"><span>IN VÉ DÒ TRÊN FIREFOX</span></a>
                                </li>
                                <li>
                                    <a href="/help/44-huong-dan-in-ve-do-ket-qua-xo-so.html"><span>Hướng Dẫn In Vé Dò Kết Quả Xổ Số</span></a>
                                </li>
                            </div>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  data () {
    return {
        menuOne: false,
        menuTwo: false,
        menuThree: false,
        menuFour: false,
        menuFive: false,
        menuSix: false,
        menuTen: false,
    }
  },
    methods: {
        mouseOver: function(status, menu){
            if (menu == 1) {
                this.menuOne = status
            }else if (menu == 2) {
                this.menuTwo = status
            }else if (menu == 3) {
                this.menuThree = status
            }else if (menu == 4) {
                this.menuFour = status
            }else if (menu == 5) {
                this.menuFive = status
            }else if (menu == 6) {
                this.menuSix = status
            }else if (menu == 10) {
                this.menuTen = status
            }
        }
    }
}
</script>