<template>
  <div class="dnw-sheet-body" style="height: auto !important">
    <!-- // COMMENT MAIN dnw_header -->
    <div class="dnw_header">
      <div class="dnw_header_l">
        <div class="dnw_header_r">
          <!-- // COMMENT SUB TopBavBar -->
          <TopBavBar />
          <!-- // COMMENT SUB Banner -->
          <Banner />
        </div>
      </div>
    </div>
    <!-- // COMMENT MAIN dnw-nav -->
    <div class="dnw-nav">
      <Nav />
    </div>
    <!-- // COMMENT MAIN dnw-content-layout -->
    <div class="dnw-content-layout" style="height: auto !important">
      <Content />
    </div>
    <!-- // COMMENT MAIN dnw-footer -->
    <div class="dnw-footer" style="margin-top: 10px">
      <Footer />
    </div>
  </div>
</template>

<script>
import TopBavBar from "../components/TopBavBar";
import Banner from "../components/Banner";
import Nav from "../components/Nav";
import Content from "../components/Content";
import Footer from "../components/Footer";

export default {
  components: {
    TopBavBar,
    Banner,
    Nav,
    Content,
    Footer,
  },
  data() {
    return {
      //
    };
  },
};
</script>
